import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';


interface Event {
    preventDefault: () => void;
    stopPropagation: () => void;
    dataTransfer: { files: File[] | undefined; };
}


@Directive({
    selector: '[appDragDrop]',
    standalone: true,
})
export class DragDropDirective {

    @Output() private files: EventEmitter<File[]> = new EventEmitter();
    @HostBinding('style.background') private background = '#fff';
    @HostBinding('style.border') private borderStyle = '2px dashed';
    @HostBinding('style.border-color') private borderColor = '#696D7D';

    constructor() {
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = 'lightgray';
        this.borderColor = 'cadetblue';
        this.borderStyle = '2px solid';
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#fff';
        this.borderColor = '#696D7D';
        this.borderStyle = '2px dashed';
    }

    @HostListener('drop', ['$event'])
    public onDrop(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
        this.background = '#fff';
        this.borderColor = '#696D7D';
        this.borderStyle = '2px dashed';
        this.files.emit(event.dataTransfer.files);
    }
}
