import { Injectable } from '@angular/core';
import { environment } from "app-env";

@Injectable({
    providedIn: 'root'
})
export class ResourcesService {

    constructor() {
    }

    public getResourceUrl(templateName: string): string {
        return `${environment.apiServer}/resource?resourceId=${templateName}`;
    }
}
