import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { provideRouter } from '@angular/router';
import { Amplify } from "aws-amplify";

import { BASE_PATH } from "src/app/backend";
import { environment } from "app-env";
import { appRoutes } from './app.routes';


Amplify.configure(environment.cognito);

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(BrowserAnimationsModule),
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(appRoutes),
        {provide: BASE_PATH, useValue: environment.basePath},
        provideHttpClient(withInterceptorsFromDi()),
    ]
};
