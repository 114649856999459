import { CanDeactivateFn } from '@angular/router';
import { Observable } from "rxjs";


export interface Deactivatable {
    deactivate: () => Observable<boolean>;
}

export const canDeactivateGuard: CanDeactivateFn<Deactivatable> = (component, currentRoute, currentState, nextState) => {
    return component.deactivate();
};
