<app-step id="analytical-performance" title="Analytical Performance">
    <ng-container subtitle>
        <p>The Analytical Performance Testing section in a 510(k) submission includes the results from laboratory tests conducted to evaluate the medical device's performance characteristics. This might encompass accuracy, precision, sensitivity, specificity, and other relevant parameters, depending on the type of device. The aim is to demonstrate that the device performs accurately and reliably in a controlled analytical setting, thereby ensuring its safety and effectiveness.</p>
        <app-guidance [resources]="resourceBundle1" [templates]="templateBundle1">
            <ng-template>
                <p>FDA performance testing process typically involves a series of laboratory tests, clinical trials, and other evaluations to assess the safety and efficacy of the product. These tests are designed to determine whether the product works as intended, what its potential side effects and risks are, and whether it is safe for use by the intended patient population.</p>
                <p>For each clinical investigation involving human subjects, the summary should include a discussion of investigative design, subject selection and exclusion criteria, investigation population, investigation period, safety and effectiveness data, adverse reactions and complications, subject discontinuation, subject complaints, device failures (including unexpected software events, if applicable) and replacements, results of statistical analyses of the clinical investigation, contraindications and precautions for use of the device, and other information from the clinical investigations as appropriate.</p>

                <h4>For <strong>De Novo Performance Testing</strong>, please attach a Summary of Studies that includes the following information:</h4>
                <ol>
                    <li>A summary of the results of the technical data contained within the submission.</li>
                    <li>A description of the objective of the study, a description of the experimental design of the study, a brief description of how the data were collected and analyzed, and a brief description of the results, whether positive, negative, or inconclusive.</li>
                    <li>A summary of each nonclinical study submitted in the submission.</li>
                    <li>A summary of each clinical investigation submitted in the submission and identify any clinical investigations conducted under an IDE.</li>
                </ol>
                Note: If no studies were conducted, please attach a statement stating no studies were conducted and that the Summary of Studies is not applicable.
            </ng-template>
        </app-guidance>
    </ng-container>
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">
            <mat-slide-toggle formControlName="hasPrecisionTesting" color="primary">
                Did you perform Precision (Repeatability/Reproducibility) Study?
                <app-guidance>
                    <ng-template>
                        <strong>Precision (Repeatability/Reproducibility) Studies</strong> contain precision characteristics information of the subject IVD medical device, which includes as appropriate;
                        <ul>
                            <li>Repeatability (within run precision),</li>
                            <li>Within laboratory precision, (within device precision)</li>
                            <li>Reproducibility (encompassing sources of variation associated with multiple laboratories and or multiple instruments, in addition to those sources affecting within laboratory imprecision.</li>
                        </ul>
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasPrecisionTesting"
                           [currentFiles]="precisionTestingFiles"
                           (onFilesAdded)="uploadPrecisionTestingFiles($event)"
                           (onFilesRemoved)="deletePrecisionTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasLinearityTesting" color="primary">
                Did you perform Linearity Study?
                <app-guidance>
                    <ng-template>
                        <strong>Linearity Studies</strong> contain linearity information of the subject IVD medical device if it relies on a quantitative measurement procedure.
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasLinearityTesting"
                           [currentFiles]="linearityTestingFiles"
                           (onFilesAdded)="uploadLinearityTestingFiles($event)"
                           (onFilesRemoved)="deleteLinearityTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasSensitivityTesting" color="primary">
                Did you perform Analytical Sensitivity/Detection Limit(s) Study?
                <app-guidance>
                    <ng-template>
                        <strong>Analytical Sensitivity/Detection Limit(s) Studies</strong> contain analytical sensitivity information of the subject IVD medical device, which may include studies to establish the limit of blank (LoB), limit of detection (LoD), and or limit of quantification (LoQ).
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasSensitivityTesting"
                           [currentFiles]="sensitivityTestingFiles"
                           (onFilesAdded)="uploadSensitivityTestingFiles($event)"
                           (onFilesRemoved)="deleteSensitivityTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasMeasurementRangeTesting" color="primary">
                Did you perform Assay Measuring Range Study?
                <app-guidance>
                    <ng-template>
                        <strong>Assay Measuring Range information</strong> contains measuring range information of the subject IVD medical device, over which errors due to imprecision, bias, and other sources are within defined limits.
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasMeasurementRangeTesting"
                           [currentFiles]="measurementRangeTestingFiles"
                           (onFilesAdded)="uploadMeasurementRangeTestingFiles($event)"
                           (onFilesRemoved)="deleteMeasurementRangeTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasSpecificityTesting" color="primary">
                Did you perform Analytical Specificity / Interference Study?
                <app-guidance>
                    <ng-template>
                        <strong>Analytical Specificity / Interference Studies</strong> contains analytical specificity (interference, including as appropriate, selectivity and cross reactivity) information of the subject IVD medical device.
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasSpecificityTesting"
                           [currentFiles]="specificityTestingFiles"
                           (onFilesAdded)="uploadSpecificityTestingFiles($event)"
                           (onFilesRemoved)="deleteSpecificityTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasCutoffTesting" color="primary">
                Did you perform Assay Cut-Off Study?
                <app-guidance>
                    <ng-template>
                        <strong>Assay Cut-Off Study</strong> contains cut-off information of the subject IVD medical device if it is a qualitative or semi-quantitative test.
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasCutoffTesting"
                           [currentFiles]="cutoffTestingFiles"
                           (onFilesAdded)="uploadCutoffTestingFiles($event)"
                           (onFilesRemoved)="deleteCutoffTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasTraceabilityTesting" color="primary">
                Did you perform Traceability Study?
                <app-guidance>
                    <ng-template>
                        <strong>Traceability information</strong> contains traceability information of the subject IVD medical device, including as appropriate:
                        <ul>
                            <li>Identification of applicable reference materials and or reference measurement procedures of higher order,</li>
                            <li>Methods of acceptance criteria for the metrological traceability,</li>
                            <li>Description of value assignment and validation.</li>
                        </ul>
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasTraceabilityTesting"
                           [currentFiles]="traceabilityTestingFiles"
                           (onFilesAdded)="uploadTraceabilityTestingFiles($event)"
                           (onFilesRemoved)="deleteTraceabilityTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasStabilityTesting" color="primary">
                Did you perform Stability Study?
                <app-guidance>
                    <ng-template>
                        <strong>Stability information</strong> contains information, such as in use and shelf-life stability of the IVD medical device components (e.g. reagents, calibrators/reference materials, control materials, any other components susceptible to degradation) and specimen’s stability.
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <app-drag-drop *ngIf="hasStabilityTesting"
                           [currentFiles]="stabilityTestingFiles"
                           (onFilesAdded)="uploadStabilityTestingFiles($event)"
                           (onFilesRemoved)="deleteStabilityTestingFiles($event)"></app-drag-drop>

            <mat-slide-toggle formControlName="hasOtherTesting" color="primary">
                Did you perform other Analytical Performance Study? Examples include the following:
                <app-guidance>
                    <ng-template>
                        <strong>Analytical Performance</strong> contains analytical performance information that may be important to the submission but does not fit in any of the other headings of Analytical Performance. (Such as Flex Testing)
                        <strong>Analytical Performance</strong>
                        <ul>
                            <li>In this step, you will indicate which Performance Test Studies have been completed or provide a justification for omitting them.</li>
                            <li>If “Yes,” to any of the following, attach study protocol(s), study results and line data for each study.</li>
                            <li>If “No,” provide a statement for each study of why information is not applicable. Example; Linearity Study – for a qualitative assay this would be N/A.</li>
                        </ul>
                    </ng-template>
                </app-guidance>
            </mat-slide-toggle>
            <ul>
                <li>Examples include the following:</li>
                <ul>
                    <li>
                        Hook Effect Testing
                        <app-guidance>
                            <ng-template>
                                <p>Hook effect is a phenomenon that can occur in certain types of analytical tests, particularly immunoassays, which are commonly used by the FDA to detect the presence of drugs, hormones, and other substances in biological samples such as blood or urine.</p>
                                <p>In the hook effect, the amount of target analyte present in the sample is so high that it exceeds the binding capacity of the antibodies used in the immunoassay. As a result, the signal measured by the assay plateaus or even decreases as more target analyte is added to the sample, leading to a false-negative result.</p>
                                <p>The FDA recognizes the hook effect as a potential issue and requires assay developers to demonstrate the absence of the hook effect in their analytical tests as part of the validation process.</p>
                            </ng-template>
                        </app-guidance>
                    </li>
                    <li>
                        Flex Testing
                        <app-guidance>
                            <ng-template>
                                <p>The robustness of the test system should be evaluated through appropriate flex studies designed to challenge the operational limits of the test system simulating conditions of use outside of the intended use environment or in instances of user error.</p>
                                <p>A secondary purpose of the flex studies is to assess the effectiveness of the built-in control measures and labeling mitigations implemented.</p>
                                <p>The flex studies should be conducted with the finished version of the test system and should encompass all steps of the testing procedure, from sample collection to reporting of the final result. If the test system includes an instrument, the flex studies should include evaluation of the effectiveness of the failsafe features built into the software.</p>
                                <p>Any observed failures during the flex studies should be evaluated to determine whether the failure is tolerable in a risk/benefit context. The studies should demonstrate that the system is robust and insensitive to user error or environmental stressors.</p>
                                <p>Flex studies should be conducted to the point of failure, where possible (i.e., you should determine the most extreme deviation which still produces accurate results).</p>
                                <p>Although it is impossible to list all the potential situations that may arise, the following table below provides an example of potential sources of errors that should be evaluated; additional studies may be required, depending on the characteristics of the individual system and risks identified in your hazard analysis.</p>
                            </ng-template>
                        </app-guidance>
                    </li>
                    <li>
                        Useability Testing / Human Factor Engineering
                        <app-guidance [resources]="resourceBundle2" [templates]="templatesBundle2">
                            <ng-template>
                                <p>With respect to Usability Testing for medical devices regulated by the U.S. Food and Drug Administration (FDA), there are specific guidelines and expectations that manufacturers must follow. The FDA emphasizes the importance of human factors engineering (HFE) and usability testing to ensure the safety and effectiveness of medical devices. Here are some key points related to FDA usability testing for medical devices:</p>
                                <ol>
                                    <li><strong>Human Factors Engineering (HFE):</strong> The FDA expects manufacturers to incorporate HFE principles into the design and development of medical devices. HFE involves considering the capabilities, limitations, and characteristics of users to optimize device usability and reduce the risk of use errors.</li>
                                    <li><strong>Usability Testing Plan:</strong> Manufacturers should develop a usability testing plan that outlines the objectives, methods, and participants for the testing process. The plan should address specific aspects of usability, such as user interface design, device instructions, and labeling.</li>
                                    <ul>
                                        <li>As an example; For Home Environment tests, such as visually read OTC tests (such as lateral flow tests that are interpreted without an instrument), it is recommended that studies be conducted that establish that the lay user can adequately interpret device results with low signal strength.</li>
                                        <li>It is recommended that such readability studies should be performed with a minimum of 50 lay users including those with vision impairment (e.g., glasses, contacts). The study should include those age groups specified in the Intended Use guidelines. If this includes all ages groups, then, include 20% of individuals above age 55. If a claim of self-testing with your device is made for a pediatric population, an adequate number of pediatric individuals of the specific age group claimed should also be included. It is recommended to include at least 20% of individuals between ages 20 and 30, and 40% individuals between 30 and 50.</li>
                                    </ul>
                                    <li><strong>Risk Management:</strong> refer to Risk Management section below.</li>
                                    <li><strong>Test Participants:</strong> Usability testing should involve representative users who have characteristics similar to the intended users of the medical device. This can include healthcare professionals, patients, caregivers, or other relevant individuals. The FDA encourages manufacturers to involve end-users in the testing process.</li>
                                    <li><strong>Test Environment:</strong> Usability testing should be conducted in an environment that simulates the intended use of the medical device. This can include healthcare facilities or other settings where users would typically interact with the device.</li>
                                    <li><strong>Test Metrics:</strong> Manufacturers are expected to define specific usability metrics to evaluate the performance of the medical device. These metrics can include task completion rates, time to complete tasks, error rates, user satisfaction ratings, and other relevant measures.</li>
                                    <li><strong>Iterative Testing:</strong> Usability testing is typically conducted iteratively throughout the device development process. Manufacturers should perform testing at different stages, from early prototypes to final designs, to identify and address usability issues.</li>
                                    <li><strong>Reporting:</strong> After completing usability testing, manufacturers should document and report the testing process, findings, and any proposed design changes or mitigations to address identified usability issues. The FDA expects a thorough summary of the testing results and the rationale behind design decisions.</li>
                                </ol>
                                <p>It is important for manufacturers to review the specific FDA guidance documents relevant to their device type and classification. The FDA provides detailed guidance on human factors and usability engineering, including specific considerations for different types of medical devices. Early engagement with the FDA is recommended to ensure compliance with applicable regulations and expectations.</p>
                                <h3>HFE Risk Management</h3>
                                <p>The U.S. Food and Drug Administration (FDA) emphasizes the importance of incorporating usability risk management into the development of medical devices. Usability risk management focuses on identifying and mitigating risks associated with the use of a medical device by considering the user interface, user interactions, and potential use errors. Here are some key points regarding FDA's expectations for usability risk management:</p>
                                <ol>
                                    <li>Usability Engineering Process: The FDA expects manufacturers to integrate usability engineering into their overall risk management process. This involves identifying and analyzing potential use-related hazards and minimizing risks through the design and development of the device.</li>
                                    <li>Usability Risk Analysis: Manufacturers should conduct a systematic analysis of usability-related hazards and potential use errors. This analysis should consider factors such as user characteristics, device design, user interface, and intended use environment. The goal is to identify and prioritize risks associated with use errors that could lead to harm.</li>
                                    <li>Risk Control Measures: Manufacturers should implement risk control measures to mitigate identified usability risks. This may involve modifying the device design, providing user instructions and warnings, incorporating safety features, or conducting additional usability testing. The effectiveness of these measures should be assessed and documented.</li>
                                    <li>Usability Validation: The FDA expects manufacturers to conduct usability validation studies to demonstrate that the device can be used safely and effectively by its intended users. Usability validation involves evaluating the device's performance with representative users in simulated or actual use environments. The results of usability validation studies should inform any necessary design modifications.</li>
                                    <li>Usability Data Collection: Manufacturers should collect and analyze usability data throughout the development process to inform risk management decisions. This includes data from usability testing, user feedback, complaints, adverse events, and post-market surveillance. The FDA expects manufacturers to establish systems for collecting and evaluating this data to identify potential usability issues.</li>
                                    <li>Usability Reporting: As part of the regulatory submission process, manufacturers should provide documentation of the usability engineering activities, including the usability risk analysis, risk control measures, and usability validation results. This information should be included in the device's risk management file and submitted to the FDA for review.</li>
                                </ol>
                                <p>It is important for manufacturers to refer to specific FDA guidance documents, such as "Applying Human Factors and Usability Engineering to Medical Devices," for detailed recommendations and expectations regarding usability risk management. Manufacturers should also engage in early and ongoing discussions with the FDA to ensure compliance with applicable regulations and to address any questions or concerns related to usability risk management.</p>
                            </ng-template>
                        </app-guidance>
                    </li>
                </ul>
            </ul>
            <app-drag-drop *ngIf="hasOtherTesting"
                           [currentFiles]="otherTestingFiles"
                           (onFilesAdded)="uploadOtherTestingFiles($event)"
                           (onFilesRemoved)="deleteOtherTestingFiles($event)"></app-drag-drop>
        </form>
    </ng-container>
</app-step>
