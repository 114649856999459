import { Routes } from "@angular/router";
import { canDeactivateGuard } from "src/app/guards/can-deactivate.guard";
import {
    PerformanceTestingComponent
} from "src/app/components/pages/performance-testing/performance-testing.component";
import { AnalyticalPerformanceComponent } from "src/app/components/pages/performance-testing/analytical-performance/analytical-performance.component";
import { ClinicalStudiesComponent } from "src/app/components/pages/performance-testing/clinical-studies/clinical-studies.component";
import { ComparisonStudiesComponent } from "src/app/components/pages/performance-testing/comparison-studies/comparison-studies.component";
import {
    GuidanceAndSpecialControlsAdherenceComponent
} from "src/app/components/pages/performance-testing/guidance-and-special-controls-adherence/guidance-and-special-controls-adherence.component";
import { PerformanceSummaryComponent } from "src/app/components/pages/performance-testing/performance-summary/performance-summary.component";


export const PerformanceTestingRoutes: Routes = [
    {
        path: 'performance-testing', component: PerformanceTestingComponent, children: [
            {
                path: '',
                redirectTo: 'analytical-performance',
                pathMatch: 'full'
            },
            {
                path: 'analytical-performance',
                component: AnalyticalPerformanceComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Analytical Performance',
                    nextSectionUrl: '../comparison-studies'
                }
            },
            {
                path: 'comparison-studies',
                component: ComparisonStudiesComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Comparison Studies',
                    nextSectionUrl: '../clinical-studies'
                }
            },
            {
                path: 'clinical-studies',
                component: ClinicalStudiesComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Clinical Studies',
                    nextSectionUrl: '../performance-summary'
                }
            },
            {
                path: 'performance-summary',
                component: PerformanceSummaryComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Performance Summary',
                    nextSectionUrl: '../guidance-and-special-controls-adherence'
                }
            },
            {
                path: 'guidance-and-special-controls-adherence',
                component: GuidanceAndSpecialControlsAdherenceComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Guidance and Special Controls Adherence',
                    nextSectionUrl: undefined
                }
            }
        ]
    }];
