<div class="drop-zone" appDragDrop (files)="onFilesDropped($event)">
    <div>
        {{ label ? label : 'Drag and drop files here or' }}
        <button mat-stroked-button color="primary" (click)="openFileInput()" class="inline-button">Select Files</button>
        <input type="file" id="fileInput" style="display: none" (change)="onSelectedFiles($event)" multiple>
    </div>
    <div class="attachments-list">
        <div class="mb-50 w-100p" *ngIf="datasource.length != 0">
            <table mat-table matSort class="w-100p" [dataSource]="datasource" *ngIf="datasource.length > 0">

                <ng-container matColumnDef="fileName">
                    <th mat-header-cell mat-sort-header class="w-65p" *matHeaderCellDef>File Name</th>
                    <td mat-cell class="w-65p" *matCellDef="let file">{{ file.fileName }}</td>
                </ng-container>

                <ng-container matColumnDef="uploadDate">
                    <th mat-header-cell mat-sort-header class="w-15p" *matHeaderCellDef>Upload Date</th>
                    <td mat-cell class="w-15p" *matCellDef="let file">{{ file.uploadDate | date: 'MMM dd, yyyy' }}</td>
                </ng-container>

                <ng-container matColumnDef="fileSize">
                    <th mat-header-cell mat-sort-header class="w-25p" *matHeaderCellDef>File Size</th>
                    <td mat-cell class="w-15p" *matCellDef="let file">{{ file.fileSize | bytesToKilobytes }}</td>
                </ng-container>

                <ng-container matColumnDef="download">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let file">
                        <button mat-stroked-button color="primary" (click)="downloadFile(file)">Download</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let file">
                        <button mat-stroked-button color="warn" (click)="deleteFile(file)">Delete</button>
                    </td>
                </ng-container>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" [colSpan]="this.displayedColumns.length">No files have been uploaded</td>
                </tr>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
