<app-step title="Project Plan">
    <ng-container subtitle>
        <p>Compass can help you estimate the cost of the submission compared to competitors.</p>
    </ng-container>
    <ng-container content>
        <mat-accordion class="mat-elevation-z0" multi>
            <mat-expansion-panel *ngFor="let step of steps | keyvalue: originalOrder">
                <mat-expansion-panel-header>
                    <mat-panel-title>{{ step.key }}</mat-panel-title>
                </mat-expansion-panel-header>

                <table mat-table [dataSource]="step.value" class="mat-elevation-z0">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td mat-cell *matCellDef="let step"> {{step.name}} </td>
                        <td mat-footer-cell *matFooterCellDef> Total </td>
                    </ng-container>

                    <ng-container matColumnDef="totalHours">
                        <th mat-header-cell *matHeaderCellDef>Total Hours</th>
                        <td mat-cell *matCellDef="let step"> {{step.totalHours}} </td>
                        <td mat-footer-cell *matFooterCellDef> {{getTotalHours(step.value)}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>
</app-step>

<app-step title="Project Budget">
    <ng-container subtitle>
        <p>Compass can help you estimate the cost of the submission compared to competitors.</p>
    </ng-container>
    <ng-container content>
        <form [formGroup]="formGroup">
            <mat-form-field appearance="fill">
                <mat-label>Number of Protocols and Reports</mat-label>
                <input matInput formControlName="protocolCount" type="number">
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Estimated Number of Hours for Lab Testing</mat-label>
                <input matInput formControlName="labTestingHours" type="number">
            </mat-form-field>

<!--            <mat-form-field appearance="fill">-->
<!--                <mat-label>Competitor Hourly Rate</mat-label>-->
<!--                <input matInput formControlName="competitorHourlyRate" type="number">-->
<!--            </mat-form-field>-->

            <mat-form-field appearance="fill">
                <mat-label>Lakeside Lifescience's Estimated Costs</mat-label>
                <input matInput readonly [value]="costEstimate">
            </mat-form-field>

<!--            <mat-form-field appearance="fill">-->
<!--                <mat-label>Competitor's Estimated Costs</mat-label>-->
<!--                <input matInput readonly [value]="competitorCostEstimate">-->
<!--            </mat-form-field>-->
        </form>
    </ng-container>
</app-step>
<!--<button mat-raised-button color="primary" *ngIf="filingType != Filing.PMA && filingType != Filing.NON_MEDICAL_DEVICE"-->
<!--        routerLink="/performance-testing"-->
<!--        class="mr-10">Initiate Execute Phase</button>-->
<!--<button mat-raised-button color="primary" *ngIf="filingType === Filing.PMA" class="mr-10"-->
<!--        disabled>Unsupported Submission Type</button>-->
<!--<button mat-raised-button color="primary" *ngIf="filingType === Filing.NON_MEDICAL_DEVICE" class="mr-10"-->
<!--        disabled>Submission is Not Needed</button>-->
