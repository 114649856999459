import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, forkJoin, map, Observable, of, switchMap, take, tap } from "rxjs";
import { Filing } from "src/app/models/filing";
import { MedicalDeviceState } from "src/app/models/medicalDeviceState";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { LoadingService } from "src/app/services/loading-service.service";
import { ProductClassificationService } from "src/app/services/product-classification.service";
import {
    Overview,
    PageProductClassification,
    PerformanceTesting,
    PredicateDevice,
    ProductClassification,
    Submission,
    SubmissionControllerService, SubmissionPage, UploadedFile
} from "src/app/backend";

@Injectable({
    providedIn: 'root'
})
export class SubmissionService {

    private selectedSubmissionSubject: BehaviorSubject<Submission> = new BehaviorSubject<Submission>({} as Submission);

    private generateSubmissionStub(): Submission {
        return {
            id: 0,
            overview: {
                deviceRegistration: {
                    productName: 'Unspecified',
                    description: 'Unspecified'
                }
            } as Overview,
            performanceTesting: {} as PerformanceTesting,
        } as Submission;
    }

    constructor(
        private submissionControllerService: SubmissionControllerService,
        private productClassificationService: ProductClassificationService,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
    ) {
    }

    public set selectedSubmission(submission: Submission) {
        this.selectedSubmissionSubject.next(submission);
    }

    public get selectedSubmission$(): Observable<Submission> {
        return this.selectedSubmissionSubject.asObservable();
    }

    public get selectedSubmission(): Submission {
        return this.selectedSubmissionSubject.getValue();
    }

    public getSubmission(id: number): Observable<Submission> {
        return this.submissionControllerService.getSubmissionById(id);
    }

    public createSubmission(): Observable<Submission> {
        return this.submissionControllerService.save(this.generateSubmissionStub());
    }

    public patchSelectedSubmission(patch: Partial<Submission>): Observable<Submission> {
        return this.submissionControllerService.save({
            ...this.selectedSubmission,
            ...patch
        }).pipe(
            tap((patchedSubmission: Submission) => {
                this.selectedSubmission = patchedSubmission;
            })
        );
    }

    public getSubmissionSummaries(page: number = 0, size: number = 0): Observable<SubmissionPage> {
        return this.submissionControllerService.getAllSubmissions(page, size);
    }

    public uploadFiles(submission: Submission, fileDiscriminator: string, files: Set<File>): void {
        this.loadingService.show();
        this.submissionControllerService.uploadAttachments(submission.id!, fileDiscriminator, files)
            .pipe(
                take(1),
                finalize(() => this.loadingService.hide()),
            )
            .subscribe({
                next: (submission: Submission): void => {
                    this.selectedSubmission = submission;
                },
                error: (error: any): void => {
                    console.log(error);
                    this.errorHandlerService.showError(error);
                }
            });
    }

    public deleteSubmission(submission: Submission): Observable<String> {
        return this.submissionControllerService._delete(submission.id!);
    }

    public removeFiles(submission: Submission, fileType: string, files: Set<File>): void {
        const fileNames: Array<string> = Array.from(files, file => file.name);
        this.loadingService.show();
        this.submissionControllerService.deleteAttachment(submission.id!, fileType, fileNames)
            .pipe(
                take(1),
                finalize(() => this.loadingService.hide()),
            )
            .subscribe({
                next: (submission: Submission): void => {
                    console.log(submission);
                    this.selectedSubmission = submission;
                },
                error: (error: any): void => {
                    console.log(error);
                    this.errorHandlerService.showError(error);
                }
            });
    }

    public downloadAttachment(submission: Submission, uploadedFile: UploadedFile): Observable<Blob> {
        return this.submissionControllerService.getAttachment(submission.id!, uploadedFile.id!);
    }

    public getMedicalDeviceState(): MedicalDeviceState {
        // Just to be safe, we are being overly explicit with the condition checks. Logically, we should only need to check for the first three conditions.
        const medicalDevice = this.selectedSubmission.overview?.medicalDevice;
        if ((medicalDevice?.meetsCriteria1 || medicalDevice?.meetsCriteria2 || medicalDevice?.meetsCriteria3) && !medicalDevice?.noCriteriaMet) {
            return MedicalDeviceState.MEDICAL_DEVICE;
        } else if (!medicalDevice?.meetsCriteria1 && !medicalDevice?.meetsCriteria2 && !medicalDevice?.meetsCriteria3 && medicalDevice?.noCriteriaMet) {
            return MedicalDeviceState.NON_MEDICAL_DEVICE;
        } else {
            return MedicalDeviceState.UNKNOWN;
        }
    }

    /**
     * Determines if the recommendation for filing.
     */
    public getFilingRecommendation$(): Observable<Filing> {
        if (this.getMedicalDeviceState() == MedicalDeviceState.UNKNOWN) {
            // 0. Have the actually completed the workflow?
            return of(Filing.NONE);
        }

        if (this.getMedicalDeviceState() == MedicalDeviceState.NON_MEDICAL_DEVICE) {
            // 1. No filing is required if the device is not a medical device
            return of(Filing.NON_MEDICAL_DEVICE);
        }

        if (this.selectedSubmission.overview?.predicateDevices == null || this.selectedSubmission.overview?.predicateDevices == undefined) {
            return of(Filing.DE_NOVO);
        }

        if (this.selectedSubmission.overview?.predicateDevices?.length == 0) {
            // 2. A De Novo is required if there are no predicate devices
            return of(Filing.DE_NOVO);
        }

        return forkJoin(
            // Retrieve the Product Classification for each Predicate Device.
            this.selectedSubmission.overview!.predicateDevices!.map((predicateDevice: PredicateDevice): Observable<PageProductClassification> => {
                return this.productClassificationService.fetchProductClassifications({productCode: predicateDevice.productCode} as ProductClassification)
            })
        ).pipe(
            // Convert each response page into a single Product Classification.
            map((classificationPages: PageProductClassification[]): ProductClassification[] => {
                // TODO: We should error check in case the product code is not found, or if there are backend issues.
                return classificationPages.map((pageProductClassification: PageProductClassification) => pageProductClassification.content ? pageProductClassification.content[0] : {} as ProductClassification)
            }),
            // Convert each Product Classification into a boolean indicating if it is exempt from 510(k).
            switchMap((productClassifications: ProductClassification[]) => {
                return forkJoin(
                    productClassifications.map((productClassification: ProductClassification): Observable<boolean> => {
                        return this.productClassificationService.fetchProductClassifications({regulationNumber: productClassification.regulationNumber} as ProductClassification)
                            .pipe(
                                map((exemptionResponse: PageProductClassification): boolean => {
                                    return exemptionResponse.content ? exemptionResponse.content.length > 0 : false;
                                })
                            );
                    })
                ).pipe(
                    map(isExempt => isExempt.every(result => result)),
                    map(allExempt => {

                        if (allExempt) {
                            // 3. All Product Classifications are exempt from 510(k).
                            return Filing.FTK;  // TODO: This should be FTKE.
                        }

                        // Identify the highest device classification.
                        const maxClassification = productClassifications.reduce((maxClassification: number, productClassification: ProductClassification) => {
                            return Math.max(maxClassification, Number(productClassification.deviceClass));
                        }, 0);

                        // 4. If the highest device classification is Class I or Class II, then a 510(k) is required; otherwise a PMA is required.
                        return (maxClassification <= 2) ? Filing.FTK : Filing.PMA;
                    })
                );
            })
        );
    }
}
