/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PredicateDevice } from './predicateDevice';
import { MedicalDevice } from './medicalDevice';
import { DeviceRegistration } from './deviceRegistration';


export interface Overview { 
    submissionType?: Overview.SubmissionTypeEnum;
    deviceRegistration: DeviceRegistration;
    medicalDevice: MedicalDevice;
    predicateDevices: Array<PredicateDevice>;
}
export namespace Overview {
    export type SubmissionTypeEnum = 'FTK' | 'PMA' | 'ODE' | 'FTKE' | 'HDE' | 'ED' | 'EUA';
    export const SubmissionTypeEnum = {
        Ftk: 'FTK' as SubmissionTypeEnum,
        Pma: 'PMA' as SubmissionTypeEnum,
        Ode: 'ODE' as SubmissionTypeEnum,
        Ftke: 'FTKE' as SubmissionTypeEnum,
        Hde: 'HDE' as SubmissionTypeEnum,
        Ed: 'ED' as SubmissionTypeEnum,
        Eua: 'EUA' as SubmissionTypeEnum
    };
}


