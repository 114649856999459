<app-step id="clinical-studies" title="Clinical Studies">
    <ng-container subtitle>
        <p>The Clinical Studies section in a 510(k) submission includes the results of any clinical trials or studies conducted with the medical device. These studies, often carried out on human subjects, aim to demonstrate the device's safety and effectiveness in its intended use environment. The information provided may include study design, population, procedure, outcomes, adverse events, and conclusions. Not all 510(k) submissions require clinical data; the need depends on the nature of the device and its intended use. The FDA uses this data to assess whether the device delivers its intended benefits without posing undue risks to patients.</p>
        <p>Compass provides guidance on specific clinical studies for your medical device, as well as provides study protocol and report templates. </p>
        <app-guidance [resources]="resourceBundleClinicalStudies" [templates]="templatesBundle3">
            <ng-template>
                <p>FDA IVD (In Vitro Diagnostic) clinical studies are an essential component of the regulatory process for obtaining FDA approval or clearance for diagnostic tests intended for clinical use. These studies aim to assess the clinical performance and safety of the IVD product in detecting or diagnosing a specific medical condition or analyte within patient samples.</p>
                <p>Types of FDA IVD Clinical Studies:</p>
                <ol>
                    <li>Analytical Performance Studies: These studies evaluate the technical performance of the diagnostic test, focusing on its accuracy, precision, sensitivity, specificity, and other analytical parameters. The goal is to assess how well the test can reliably detect and quantify the target analyte in patient samples under controlled laboratory conditions.</li>
                    <li>Clinical Performance Studies: Clinical performance studies assess the ability of the diagnostic test to accurately diagnose or predict the presence or absence of a medical condition in real patient populations. These studies involve testing patient samples in a clinical setting and comparing the results to a reference standard or an accepted diagnostic method.</li>
                    <li>Diagnostic Accuracy Studies: These studies specifically focus on determining the diagnostic accuracy of the test, including sensitivity, specificity, positive predictive value, and negative predictive value, as compared to the gold standard or an established diagnostic method.</li>
                    <li>Clinical Utility Studies: Clinical utility studies assess the impact of using the diagnostic test on patient management and clinical decision-making. The study investigates whether the test results lead to better patient outcomes or influence treatment decisions.</li>
                    <li>Risk Assessment and Safety Studies: For some high-risk diagnostic tests, additional safety studies may be required to evaluate any potential risks or adverse events associated with the use of the test in patients.</li>
                </ol>
                <p>The FDA provides guidance to manufacturers on the design and conduct of clinical studies for IVD devices. The specific requirements depend on the intended use and risk classification of the diagnostic test. Typically, higher-risk tests, such as those used to diagnose life-threatening conditions, require more extensive clinical data than lower-risk tests.</p>
                <p>Key elements of FDA IVD clinical studies include:</p>
                <ul>
                    <li>Clearly defined study objectives and endpoints.</li>
                    <li>Well-characterized and representative patient populations.</li>
                    <li>Appropriate comparator methods or reference standards.</li>
                    <li>Rigorous study design, including randomization and blinding where applicable.</li>
                    <li>Adequate sample size and statistical analysis to ensure the study's validity.</li>
                    <li>Data collection and analysis that comply with Good Clinical Practice (GCP) standards.</li>
                    <li>Ethical considerations and informed consent from study participants.</li>
                    <li>Adherence to FDA regulations and guidance documents.</li>
                </ul>
                <p>It is important to note that clinical studies for IVD products can be complex and time-consuming processes. Manufacturers seeking FDA approval or clearance for their diagnostic tests must plan and execute these studies carefully to generate robust and reliable clinical evidence supporting the test's safety and effectiveness.</p>
                <p>For the most up-to-date and specific information on FDA requirements for IVD clinical studies, it's essential to refer to the latest FDA guidance documents and regulations applicable to the particular diagnostic test or medical device in question.</p>
            </ng-template>
        </app-guidance>
    </ng-container>
</app-step>

<app-step title="Good Clinical Practice (GCP) for Clinical Study Management ">
    <ng-container subtitle>
        <p>In this section, you will find Good Clinical Practice (GCP) Documentation for sponsors of clinical trials.</p>
        <p>Good Clinical Practice (GCP) is a set of internationally recognized ethical and scientific standards that guides the design, conduct, performance, monitoring, auditing, recording, analysis, and reporting of clinical trials involving human subjects. GCP aims to ensure the safety, rights, and well-being of trial participants and the reliability and credibility of the trial results.</p>
        <p>GCP provides a framework for the proper planning, execution, and documentation of clinical trials, regardless of their phase (Phase I to Phase IV) and the type of intervention being tested (drugs, biologics, medical devices, etc.). It covers various aspects of clinical trial management,</p>
        <p>Documentation for a clinical study is a crucial aspect of ensuring the integrity, transparency, and compliance of the study. Proper documentation helps regulatory authorities, study sponsors, investigators, and other stakeholders assess the validity and reliability of the study results.</p>
        <p>A clinical study binder is a collection of essential documents and information related to the conduct of a clinical study for an in vitro diagnostic medical device. This binder serves as a central repository of information for all aspects of the clinical study and is typically used by the study sponsor, investigators, and regulatory authorities to ensure that the study is conducted in a compliant and organized manner.</p>
    </ng-container>
    <ng-container content>
        <div class="parent">
            <div class="header1"><strong>Section Name</strong></div>
            <div class="header2"><strong>Description SOP Number</strong></div>
            <div class="header3"><strong>Name and Associated Forms</strong></div>

            <!--            SOP 101-->
            <div class="sop100 title">
                <div class="subsection">
                    <strong>General Administration (101-105)</strong>
                    Outlines the activities that form the infrastructure of a clinical research program. It includes sections on delegation of responsibility, document control, personnel qualifications and training, conflicts of interest (including financial disclosure) and vendor selection. It also includes a complete overview of the entire clinical evaluation process.
                </div>
            </div>

            <div class="sop101-name">SOP-101</div>
            <div class="sop101-desc">Overview of Sponsor Responsibilities</div>
            <div
                class="sop101-docs">FRM-101 A Assignment and Delegation of Responsibilities and Activities of Internal Personnel</div>

            <div class="sop102-name">SOP-102</div>
            <div class="sop102-desc">Controlled Document Development and Change Control</div>
            <div class="sop102-docs">
                <div>FRM-102-A SOP Template</div>
                <div>FRM -102-B Document Control FRM</div>
                <div>FRM-102-C Table of Modifications Form</div>
            </div>

            <div class="sop103-name">SOP-103</div>
            <div class="sop103-desc">Sponsor Research Team Training</div>
            <div class="sop103-docs">
                <div>FRM-103-A Sample Training Curricula</div>
                <div>FRM-103-B Training Completion Record</div>
                <div>FRM-103-C Controlled Document Training Record</div>
            </div>

            <div class="sop104-name">SOP-104</div>
            <div class="sop104-desc">Conflict of Interest Disclosure Requirements</div>
            <div class="sop104-docs">
                <div>FRM-104-A FDA Form 3454</div>
                <div>FRM-104-B FDA Form 3455</div>
            </div>

            <div class="sop105-name">SOP-105</div>
            <div class="sop105-desc">Outsourcing Services for Clinical Investigations</div>
            <div class="sop105-docs">
                <div>FRM-105-A-Vendor Audit Report</div>
                <div>FRM-105-B Vendor Eval Form</div>
                <div>FRM-105-C Request for Proposal</div>
                <div>FRM-105-D Clinical Trial Agreement</div>
                <div>FRM-105-E Confidentiality Agreement</div>
            </div>

            <!--            SOP 201-->
            <div class="sop200 title">
                <div class="subsection">
                    <strong>Regulatory Affairs (201-203)</strong>
                    Includes information about working with the FDA and outlines requirements for submissions, supplements and reports that must be sent to the FDA either prior to or within a specified time frame after certain events.
                </div>
            </div>

            <div class="sop201-name">SOP-201</div>
            <div class="sop201-desc">FDA Contracts and Meetings</div>
            <div class="sop201-docs">
                <div>FRM-201-A-Accessing FDA Docs on the Internet</div>
            </div>

            <div class="sop202-name">SOP-202</div>
            <div class="sop202-desc">Regulatory Submissions</div>
            <div class="sop202-docs">
                <div>FRM-202-A Medical Device Risk Determination Form</div>
                <div>FRM-202-B IDE Content and Format Checklist</div>
                <div>FRM-202-C IDE Supplement Checklist</div>
            </div>

            <div class="sop203-name">SOP-203</div>
            <div class="sop203-desc">Regulatory Reporting Requirements</div>
            <div class="sop203-docs">
                <div>FRM-203-A IDE progress Report Guidelines</div>
                <div>FRM-203-B IDE Final Report Guidelines</div>
                <div>FRM-203-C Reporting Requirements and Responsibilities Checklist</div>
            </div>

            <div class="sop300 title">
                <div class="subsection">
                    <strong>Clinical Protocol (301-302)</strong>
                    includes requirements for protocol development review and approval and related documents (Investigational Plan and Report of Prior Investigations).
                </div>
            </div>

            <div class="sop301-name">SOP-301</div>
            <div class="sop301-desc">Investigational Plan Development</div>
            <div class="sop301-docs">
                <div>FRM 301-A Investigational Plan (IP) Checklist</div>
                <div>FRM 301-B Clinical Protocol Checklist</div>
                <div>FRM 301-C Clinical Protocol Amendment Checklist</div>
            </div>

            <div class="sop302-name">SOP-302</div>
            <div class="sop302-desc">Report of Prior Investigations</div>
            <div class="sop302-docs">
                <div>FRM 302-A Report of Prior Investigations Checklist</div>
            </div>

            <div class="sop400 title">
                <div class="subsection">
                    <strong>Study Startup (401-402)</strong>
                    includes the required steps to select and qualify investigative sites and to initiate a study at a research site, including processes for site training on the protocol, regulatory compliance and associated reporting and recordkeeping.
                </div>
            </div>

            <div class="sop401-name">SOP-401</div>
            <div class="sop401-desc">Investigator Selection and Qualification</div>
            <div class="sop401-docs">
                <div>FRM 401-A Clinical Investigator Assessment</div>
                <div>FRM 401-B Clinical Investigator Qualification Checklist</div>
            </div>

            <div class="sop402-name">SOP-402</div>
            <div class="sop402-desc">Site Enrollment and Initiation</div>
            <div class="sop402-docs">
                <div>FRM-402-A Site Initiation Visit Report Form</div>
                <div>FRM-402-B Clinical Site signature ad Delegation Log</div>
                <div>FRM-402-C Clinical Site Training Record</div>
                <div>FRM-402-D Site Visit Log</div>
                <div>FRM-402-E Clinical Investigator Agreement</div>
                <div>FRM-105-D Clinical Trial Agreement </div>
            </div>

            <div class="sop500 title">
                <div class="subsection">
                    <strong>Project Management (501-505)</strong>
                    Describes processes for managing clinical investigations, including investigational product management and study documentation requirements. It also includes procedures for risk-based, on-site and centralized study monitoring; managing protocol violations and other investigator noncompliance; and study closeout.
                </div>
            </div>
            <div class="sop501-name">SOP-501</div>
            <div class="sop501-desc">Investigational Device Management</div>
            <div class="sop501-docs">
                <div>FRM-501-A Investigational Device Release Form</div>
                <div>FRM-501-B Individual Subject Device Control Form</div>
                <div>FRM-501-C Investigational Device Inventory Form</div>
            </div>
            <div class="sop502-name">SOP-502</div>
            <div class="sop502-desc">Documentation and Records Retention</div>
            <div class="sop502-docs">
                <div>FRM-502-A Sponsor Trial Master File and Site Study File Contents</div>
            </div>
            <div class="sop503-name">SOP-503</div>
            <div class="sop503-desc">Monitoring Clinical Investigations</div>
            <div class="sop503-docs">
                <div>FRM-503-A Monitoring Plan Development</div>
                <div>FRM-503-B Monitoring Visit Checklist and Report</div>
                <div>FRM-503-C Protocol Deviation Report</div>
                <div>FRM-503-D Communications Log </div>
                <div>FRM-503-E Remote Monitoring Report</div>
            </div>

            <div class="sop504-name">SOP-504</div>
            <div class="sop504-desc">Study Closeout</div>
            <div class="sop504-docs">
                <div>FRM-504-A Study Closeout Report</div>
                <div>FRM-504-B Study Site Closure and CSA Termination Letter</div>
                <div>FRM-504-C Study Procedures Verification Memo</div>
            </div>

            <div class="sop505-name">SOP-505</div>
            <div class="sop505-desc">Managing Significant Safety Events, Noncompliance and Study Termination</div>
            <div class="sop505-docs">
                <div>FRM-PM 505-A Noncompliance Evaluation Checklist</div>
                <div>FRM-PM 505-B Documentation of Actions to Ensure Investigator Compliance</div>
            </div>

            <div class="sop600 title">
                <div class="subsection">
                    <strong>Subject Management (601-606)</strong>
                    Provides procedures related to protection of research subjects. These requirements include IRB review, informed consent and health information privacy protection (HIPAA), as well as recruitment practices, subject screening and enrollment. Additional risk mitigation procedures are provided for specimen management and for tracking and reporting Adverse Device Effects (ADE).
                </div>
            </div>

            <div class="sop601-name">SOP-601</div>
            <div class="sop601-desc">Human Subject Protection</div>
            <div class="sop601-docs">
                <div>FRM-601-A IRB Submission Checklist</div>
                <div>FRM-601-B Informed Consent Form Template</div>
                <div>FRM-601-C Informed Consent Form Checklist</div>
            </div>

            <div class="sop602-name">SOP-602</div>
            <div class="sop602-desc">Subject Recruitment Practices</div>
            <div class="sop602-docs">
                <div>FRM-602-A Guidelines for Recruitment and Advertising</div>
            </div>

            <div class="sop603-name">SOP-603</div>
            <div class="sop603-desc">Subject Eligibility and Enrollment</div>
            <div class="sop603-docs">
                <div>FRM-603-A Subject Eligibility Checklist</div>
                <div>FRM-603-B Screening and Enrollment Log</div>
            </div>

            <div class="sop604-name">SOP-604</div>
            <div class="sop604-desc">Specimen Management</div>
            <div class="sop604-docs">
                <div>FRM-604-A Specimen Shipping Log</div>
            </div>

            <div class="sop605-name">SOP-605</div>
            <div class="sop605-desc">Adverse Device Effects Recognition and Reporting</div>
            <div class="sop605-docs">
                <div>FRM-605-A Adverse Device Effect Report</div>
                <div>FRM-605-B Adverse Device Effect and Intercurrent Illness Log</div>
                <div>FRM-605-C Reporting Unanticipated Adverse Device Effects to the IRB</div>
            </div>

            <div class="sop606-name">SOP-606</div>
            <div class="sop606-desc">Protected Health Information</div>
            <div class="sop606-docs">
                <div>FRM-606-A Sample Data Agreement</div>
                <div>FRM-606-B Waiver of Authorization Checklist</div>
                <div>FRM-606-C Required Elements: Authorization</div>
                <div>FRM-606-D Authorization Template</div>
            </div>

            <div class="sop700 title">
                <div class="subsection">
                    <strong>Data Management (701)</strong>
                    Focuses on the management and handling of data collected during the clinical investigation at study sites and the use of Electronic Data Capture (EDC) systems.
                </div>
            </div>

            <div class="sop701-name">SOP-701</div>
            <div class="sop701-desc">Clinical Data Management</div>
            <div class="sop701-docs">
                <div>FRM-701-A Subject Data Clarification Form</div>
                <div>FRM-701-B Source Documentation Requirements</div>
            </div>

            <div class="sop800 title">
                <div class="subsection">
                    <strong>Quality Assurance (801-802)</strong>
                    Provides procedures for conducting internal audits, auditing investigative sites, managing an FDA inspection, and assisting a Clinical Investigator in preparing for an FDA inspection.
                </div>
            </div>

            <div class="sop801-name">SOP-801</div>
            <div class="sop801-desc">Quality Assurance Audits</div>
            <div class="sop800-docs">
                <div>FRM-801-A Quality Assurance Audit Form</div>
            </div>

            <div class="sop802-name">SOP-802</div>
            <div class="sop802-desc">Regulatory Inspection</div>
            <div class="sop802-docs">
                <div>FRM-802-A Preparing a Site for an FDA inspection Checklist</div>
                <div>FRM-802-B Procedures for an FDA Sponsor Inspection Checklist</div>
            </div>
        </div>
    </ng-container>
</app-step>

<app-step title="Clinical Study Protocol">
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">
            <mat-slide-toggle formControlName="hasClinicalStudies"
                              color="primary">Do you have Clinical Study information to include in this submission?</mat-slide-toggle>

            <ng-container *ngIf="hasClinicalStudies">
<!--                <p>Attach study protocol(s), study results, and line data for each study.</p>-->
                <!--                <app-drag-drop-->
                <!--                    [currentFiles]="clinicalStudiesFiles"-->
                <!--                    (onFilesAdded)="uploadClinicalStudiesFiles($event)"-->
                <!--                    (onFilesRemoved)="deleteClinicalStudiesFiles($event)"></app-drag-drop>-->

                <mat-slide-toggle
                    formControlName="hasSensitivityStudies"
                    color="primary">Do you have Clinical Sensitivity and/or Clinical Specificity information to include in this submission?</mat-slide-toggle>
                <ng-container *ngIf="hasSensitivityStudies">
                    <p>Attach documentation that includes details of Clinical Sensitivity and/or Clinical Specificity information for your device, including associated study protocol(s), study results and line data.</p>
                    <app-drag-drop
                        [currentFiles]="sensitivityStudiesFiles"
                        (onFilesAdded)="uploadSensitivityStudiesFiles($event)"
                        (onFilesRemoved)="deleteSensitivityStudiesFiles($event)"></app-drag-drop>
                </ng-container>

                <mat-slide-toggle formControlName="hasCutoffInformation"
                                  color="primary">Do you have Clinical Cut-Off information to include in this submission?</mat-slide-toggle>
                <ng-container *ngIf="hasCutoffInformation">
                    <p>Attach documentation that includes details of Clinical Cut-Off information for your device, including associated study protocol(s), study results and line data.</p>
                    <app-drag-drop
                        [currentFiles]="cutoffInformationFiles"
                        (onFilesAdded)="uploadCutoffInformationFiles($event)"
                        (onFilesRemoved)="deleteCutoffInformationFiles($event)"></app-drag-drop>
                </ng-container>

                <mat-slide-toggle formControlName="hasSupportiveData" color="primary">
                    Do you have other Clinical Supportive Data to include in this submission?
                    <app-guidance>
                        <ng-template>
                            <p>A full/complete test report includes: objective of the test, description of the test methods and procedures, study endpoint(s), pre-defined pass/fail criteria, results summary, conclusions, and an explanation of how the data generated from the test support this submission.</p>
                            <p>If any regulatory guidance and/or standard documents were used/referenced for testing, cite them in your report.</p>
                        </ng-template>
                    </app-guidance>
                </mat-slide-toggle>
                <ng-container *ngIf="hasSupportiveData">
                    <p>Provide a brief summary of the other Clinical Supportive Data you included in this submission.</p>
                    <app-drag-drop
                        [currentFiles]="clinicalSupportiveDataFiles"
                        (onFilesAdded)="uploadClinicalSupportiveDataFiles($event)"
                        (onFilesRemoved)="deleteClinicalSupportiveDataFiles($event)"></app-drag-drop>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!hasClinicalStudies">
                <p>Provide a statement for each study of why information is not applicable.</p>
                <app-drag-drop
                    [currentFiles]="clinicalStudiesFiles"
                    (onFilesAdded)="uploadClinicalStudiesFiles($event)"
                    (onFilesRemoved)="deleteClinicalSupportiveDataFiles($event)"></app-drag-drop>
            </ng-container>
        </form>
    </ng-container>
</app-step>

<app-step id="patient-reported-outcomes-and-patient-preference-information"
          title="Patient-Reported Outcomes and Patient Preference Information">
    <ng-container subtitle>
        <p>The Patient Reported Outcomes and Patient Preference Information section in a 510(k) submission includes data collected directly from patients about their experiences with the device. This can involve feedback on perceived benefits, adverse effects, usability, or preference over alternative treatments. Such information provides the FDA with valuable insights from the end-user perspective, supplementing the technical and clinical data to offer a holistic view of the device's safety, effectiveness, and overall acceptability. Not all 510(k) submissions may include this data, but when available, it can help inform the FDA's decision-making process.</p>
    </ng-container>
    <ng-container content>
        <app-guidance>
            <ng-template>
                <p>A Patient-Reported Outcome (PRO) is a measurement based on a report that comes directly from the patient (study subject) about the status of a patient’s health condition amendment or interpretation of the patient’s response by a clinician or anyone else. A PRO can be measured by self-report or by interview provided that the interviewer records on the patient’s response. PRO’s may capture symptoms or function. A PRO instrument is typically a questionnaire, survey, or diary.</p>
                <p>A Patient Preference Study or Patient Preference Information (PPI) is defined as qualitative assessment of the relative desirability or acceptability to patients of specified alternatives or choices among outcomes or other attributes that differ among alternative health interventions.</p>
            </ng-template>
        </app-guidance>
        <ul>
            <li>PROs are measures of health outcomes that are reported by patients themselves, such as symptoms, quality of life, and functional status.</li>
            <li>PPI is information about patients' preferences for different aspects of medical treatment, such as test attributes (e.g., ease of use, time required) or treatment outcomes (e.g., survival, quality of life).</li>
        </ul>

        <p>Identify the attachment(s) and page number(s) of any information provided related to patient reported outcomes (PROs) for this section, such as the PRO questionnaire, dossier, and/or other supportive documents.</p>
        <app-drag-drop
            [currentFiles]="ppoFiles"
            (onFilesAdded)="uploadPPOFiles($event)"
            (onFilesRemoved)="deletePPOFiles($event)"></app-drag-drop>

        <p>Identify the attachment(s) and page number(s) of any information provided related to patient preference information (PPI) for this section, such as the PPI survey, protocol, attribute table, and/or other supportive documents as appropriate.</p>
        <app-drag-drop
            [currentFiles]="ppiFiles"
            (onFilesAdded)="uploadPPIFiles($event)"
            (onFilesRemoved)="deletePPIFiles($event)"></app-drag-drop>
    </ng-container>
</app-step>

<app-step id="compliance-with-good-clinical-practice-for-supporting-clinical-investigations"
          title="Compliance with Good Clinical Practice for Supporting Clinical Investigations">
    <ng-container subtitle>
        <p>In this section, you will be asked if you have clinical investigation(s) in this submission that are subject to the requirements governing FDA acceptance of data from clinical investigations. If “Yes,” fill in where were the supporting clinical investigation(s) included in this submission conducted?</p>
    </ng-container>
    <ng-container content>
        <app-guidance>
            <ng-template>
                <p>FDA requirements for acceptance of clinical data from investigations is described in 83 FR 7366 (final Rule titled “Human Subject Protection’ Acceptance of Data From Clinical Investigations for Medical Devices”). Clinical investigations are subject to the requirements as described in the final rule that meets the following conditions:</p>
                <ul>
                    <li>Research involving one or more human subjects to determine the safety and effectiveness of a device (21 CFR 812.3(h)).</li>
                    <li>First subject signed informed consent documents on or after February 21, 2019 (83 FR 7366).</li>
                </ul>
                <p>Choose “Yes” if both these conditions are true for one or more clinical investigations in the submission, or choose “No” otherwise.</p>
            </ng-template>
        </app-guidance>
        <ol>
            <li>For each clinical investigation with US sites included in this submission, please attach a statement of compliance with 21 CFR parts 50, 56, and 812 and/or a rationale for not complying with those regulations.</li>
            <li>For each clinical investigation with OUS sites included in this submission, please attach:</li>
            <ul>
                <li>A statement of compliance with GCP per 21 CFR 812.28(a)(1), and supporting information as described in parts 21 CFR 812.28(a)(2) and (b), and/or</li>
                <li>A rationale for not providing the above referenced statement of compliance with GCP or supporting information, and/or a waiver request in accordance with 21 CFR 812.28(c)</li>
            </ul>
            <li>For clinical investigations conducted at BOTH US and OUS sites include all the above.</li>
        </ol>
        <app-drag-drop
            [currentFiles]="statementOfComplianceUsfiles"
            (onFilesAdded)="uploadStatementOfComplianceUsFiles($event)"
            (onFilesRemoved)="deleteStatementOfComplianceUsFiles($event)"></app-drag-drop>
        <app-drag-drop
            [currentFiles]="statementOfComplianceOusFiles"
            (onFilesAdded)="uploadStatementOfComplianceOusFiles($event)"
            (onFilesRemoved)="deleteStatementOfComplianceOusFiles($event)"></app-drag-drop>
    </ng-container>
</app-step>
