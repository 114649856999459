<app-step id="performance-summary" title="Performance Summary">
    <ng-container subtitle>
        <p>If the determination of substantial equivalence is also based on an assessment of performance data, it is recommended you fill out the text boxes below. If no testing was necessary, state this in the respective field below.</p>
        <p>If you choose to use a 510(k)-summary you must include this information, in accordance with 21 CFR 807.92(b). The contents of the 510(k) Summary will be made publicly available on the FDA website if your device is cleared.</p>
    </ng-container>
    <ng-container content>
        <p>Provide a brief discussion of the nonclinical tests submitted, referenced, or relied on in the 510(k) for a determination of substantial equivalence. If any guidance documents or FDA recognized consensus standards were used/referenced for testing, cite these in the space provided.</p>
        <app-drag-drop
            [currentFiles]="briefFiles"
            (onFilesAdded)="uploadBriefFiles($event)"
            (onFilesRemoved)="deleteBriefFiles($event)"></app-drag-drop>

        <app-guidance [resources]="resourceBundlePerformanceSummary">
            <p>See 21 CFR 807.92(b) for the regulatory requirements regarding this question.</p>
            <p>Be sure to include a summary of baseline demographic information and where appropriate, subgroup analyses for sex, gender, age, race, and ethnicity of the subjects.</p>
        </app-guidance>
        <p>Provide a summary discussion of the clinical tests submitted, referenced, or relied on in the 510(k) for a determination of substantial equivalence. This discussion shall include, where applicable, a description of the subjects upon whom the device was tested, a discussion of the safety or effectiveness data obtained from the testing, with specific reference to adverse effects and complications, and any other information from the clinical testing relevant to a determination of substantial equivalence. Refer to the Guidance text for a list of the details that are recommended to be included regarding the subjects and clinical evidence. If no clinical data were necessary, please type "Not Applicable." (There should not be any patient identifier information in the summary.)</p>
        <app-drag-drop
            [currentFiles]="summaryFiles"
            (onFilesAdded)="uploadSummaryFiles($event)"
            (onFilesRemoved)="deleteSummaryFiles($event)"></app-drag-drop>

        <p>State the conclusions drawn from the nonclinical and clinical tests that demonstrate that the device is as safe, as effective, and performs as well as or better than the legally marketed device identified above.</p>
        <app-drag-drop
            [currentFiles]="conclusionFiles"
            (onFilesAdded)="uploadConclusionFiles($event)"
            (onFilesRemoved)="deleteConclusionFiles($event)"></app-drag-drop>
    </ng-container>
</app-step>
