import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class ErrorHandlerService {

    constructor(
        private snackBar: MatSnackBar
    ) {
    }

    public handleError(error: any): void {
        console.error(error);
    }

    // TODO: Consolidate showString and showError into one function.
    public showString(message: string) {
        this.snackBar.open(message, 'Close', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'mat-warn']
        });
    }

    public showError(error: HttpErrorResponse) {
        this.snackBar.open(error.message, 'Close', {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: ['mat-toolbar', 'mat-warn']
        });
    }
}
