import { Routes } from "@angular/router";
import {
    ProjectPlanningComponent
} from "src/app/components/pages/overview/project-planning/project-planning/project-planning.component";
import { canDeactivateGuard } from "src/app/guards/can-deactivate.guard";

import { DeviceRegistrationComponent } from "src/app/components/pages/overview/device-registration/device-registration.component";
import { MedicalDeviceComponent } from "src/app/components/pages/overview/medical-device/medical-device.component";
import { PredicateDeviceComponent } from "src/app/components/pages/overview/predicate-device/predicate-device.component";
import { SubmissionStrategyComponent } from "src/app/components/pages/overview/submission-strategy/submission-strategy.component";


export const OverviewRoutes: Routes = [
    {
        path: 'overview', children: [
            {
                path: '',
                redirectTo: 'device-registration',
                pathMatch: 'full'
            },
            {
                path: 'device-registration',
                component: DeviceRegistrationComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Device Characteristics',
                    nextSectionUrl: '../medical-device'
                }
            },
            {
                path: 'medical-device',
                component: MedicalDeviceComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Medical Device Classification',
                    nextSectionUrl: '../predicate-device'
                }
            },
            {
                path: 'predicate-device',
                component: PredicateDeviceComponent,
                canDeactivate: [canDeactivateGuard],
                data: {
                    title: 'Predicate Device Selection',
                    nextSectionUrl: '../submission-strategy'
                }
            },
            {
                path: 'submission-strategy',
                component: SubmissionStrategyComponent,
                data: {
                    title: 'Submission Strategy',
                    nextSectionUrl: '../project-planning'
                }
            },
            {
                path: 'project-planning',
                component: ProjectPlanningComponent,
                data: {
                    title: 'Project Planning',
                    nextSectionUrl: '/performance-testing'
                }
            }
        ]
    }
];
