import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { DragDropDirective } from "src/app/directives/drag-drop.directive";
import { MATERIAL_MODULES } from "src/app/material.imports";
import { BytesToKilobytesPipe } from "src/app/pipes/bytes-to-kilobytes.pipe";
import { SubmissionService } from "src/app/services/submission.service";
import { UploadedFile } from "src/app/backend";


@Component({
    selector: 'app-drag-drop',
    templateUrl: './drag-drop.component.html',
    styleUrls: ['./drag-drop.component.scss'],
    standalone: true,
    imports: [
        BytesToKilobytesPipe,
        CommonModule,
        DragDropDirective,
        ...MATERIAL_MODULES
    ]
})
export class DragDropComponent {

    @Input() label: string = '';
    @Input() filesDiscriminator: string = '';
    @Input() currentFiles: Set<UploadedFile> = new Set()
    @Output() onFilesAdded: EventEmitter<Set<File>> = new EventEmitter<Set<File>>();
    @Output() onFilesRemoved: EventEmitter<Set<File>> = new EventEmitter<Set<File>>();

    protected displayedColumns: string[] = ['fileName', 'uploadDate', 'fileSize', 'download', 'delete'];

    constructor(
        private submissionService: SubmissionService
    ) {
    }

    protected get datasource(): UploadedFile[] {
        return Array.from(this.currentFiles);
    }

    protected openFileInput() {
        document.getElementById('fileInput')!.click();
    }

    protected onSelectedFiles(event: any): void {
        this.onFilesAdded.emit(new Set(Object.values(event.target.files)));
    }

    protected onFilesDropped(files: File[]): void {
        this.onFilesAdded.emit(new Set(Object.values(files)));
    }

    protected downloadFile(uploadedFile: UploadedFile): void {
        this.submissionService.downloadAttachment(this.submissionService.selectedSubmission, uploadedFile).subscribe(blob => {
            // Create a new Blob object
            const data = window.URL.createObjectURL(blob);

            // Create a temporary anchor tag
            const link = document.createElement('a');
            link.href = data;
            link.download = uploadedFile.fileName!

            // Append the link, trigger the click, and then remove the link
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Release the object URL
            window.URL.revokeObjectURL(data);
        });
    }

    protected deleteFile(file: UploadedFile): void {
        this.onFilesRemoved.emit(this.convertToFiles(new Set([file])));
    }

    private convertToFiles(files: Set<UploadedFile>): Set<File> {
        return new Set(Array.from(files, (file: UploadedFile) => {
            return new File([file.fileName!], file.fileName!, {type: file.fileDiscriminator});
        }));
    }
}
