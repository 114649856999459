import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { DeviceRegistration, PredicateDevice, Submission } from "src/app/backend";
import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { MATERIAL_MODULES } from "src/app/material.imports";
import { Filing } from "src/app/models/filing";
import { Experiment, protocolDocumentMap, Protocol, technologies } from "src/app/models/technology";
import { Workflow } from "src/app/models/workflow";
import { PredicateDeviceService } from "src/app/services/predicate-device.service";
import { SubmissionService } from "src/app/services/submission.service";
import {
    ProductClassificationDialogComponent
} from "src/app/components/pages/overview/predicate-device/product-classification-dialog/product-classification-dialog.component";
import { Profile } from "src/app/models/profile";

@Component({
    selector: 'app-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./summary.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule,
        ...MATERIAL_MODULES,
        GuidanceComponent
    ]
})
export class SummaryComponent implements OnInit, OnDestroy {

    protected readonly Filing = Filing;
    protected templateBundle1 = [
        {
            icon: 'description',
            name: 'Template for Clinical Comparison Study.docx',
            path: 'Template for Clinical Comparison Study.docx'
        }
    ];
    protected templateBundle2 = [
        {
            icon: 'description',
            name: 'Template for Safety and Effectiveness Protocol.docx',
            path: 'Template for Safety and Effectiveness Protocol.docx'
        }
    ]
    protected templateBundle3 = [
        {
            icon: 'description',
            name: 'Template for QSub Request.docx',
            path: 'Template for QSub Request.docx'
        }
    ];

    @Input() submission: Submission = {} as Submission;

    workflows = Workflow;
    workflow: Workflow = Workflow.NONE;
    recommendation: string = '';
    displayPresubmission: boolean = false;

    protected displayedColumns = ['deviceName', 'kNumber', 'decisionDate', 'classification', 'productCode', 'reviewAdviseComm'];
    protected protocolDisplayedColumns = ['category', 'clsi', 'title'];

    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private submissionService: SubmissionService,
        private deviceService: PredicateDeviceService,
        private dialog: MatDialog
    ) {
    }

    public ngOnInit(): void {
        this.submissionService.getFilingRecommendation$().subscribe((filing: Filing) => {
            this.setRecommendation(filing);
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public get strategy(): Filing {
        return this.submission.overview?.submissionType as Filing;
    }

    // public get userRegistration(): Profile {
    //     return this.submission.introduction?.submitter as Profile;
    // }

    public get deviceRegistration(): DeviceRegistration {
        return this.submission.overview?.deviceRegistration as DeviceRegistration;
    }

    public get predicateDevices(): PredicateDevice[] {
        return this.submission.overview?.predicateDevices as PredicateDevice[];
    }

    public setRecommendation(filing: Filing): void {
        switch (filing) {
            case Filing.NONE:
                this.workflow = Workflow.NONE;
                this.displayPresubmission = false;
                this.recommendation = 'Please complete the workflow to determine recommendations.';
                break;

            case Filing.NON_MEDICAL_DEVICE:
                this.workflow = Workflow.NONE;
                this.displayPresubmission = false;
                this.recommendation = 'FDA approval is not necessary as the device is not a medical device.';
                break;

            case Filing.DE_NOVO:
                this.workflow = Workflow.DE_NOVO;
                this.displayPresubmission = true;
                this.recommendation = 'De Novo';
                break;

            case Filing.PMA:
                this.workflow = Workflow.UNSUPPORTED;
                this.displayPresubmission = true;
                this.recommendation = 'PMA Pre-Submission';
                break;

            case Filing.FTK:
                this.workflow = Workflow.PMN;
                this.displayPresubmission = true;
                this.recommendation = '510(k)';
                break;

            case Filing.FTKE:
                this.workflow = Workflow.UNSUPPORTED;
                this.displayPresubmission = true;
                this.recommendation = '510(k) Exempt';
                break;
        }
    }

    public openDeviceSummary(kNumber: string): void {
        this.deviceService.fetchDeviceSummaryUrl(kNumber).subscribe(deviceSummary => {
            window.open(deviceSummary.url, "_blank");
        });
    }

    public openProductClassification(productClassification: string): void {
        this.dialog.open(ProductClassificationDialogComponent, {
            width: '80%',
            data: {
                productCode: productClassification
            }
        });
    }

    protected get documents(): Experiment {
        const technology: string = this.submission.overview.deviceRegistration.technology || '';
        return technologies[technology];
    }

    protected readonly technologies = technologies;
    protected readonly experimentMap = protocolDocumentMap;
    protected readonly Workflow = Workflow;
}
