<app-step id="guidance-and-special-controls-adherence" title="Guidance and Special Controls Adherence">
    <ng-container subtitle>
        <p>In this section, you will be asked to provide information demonstrating compliance with any applicable <a
            href="https://www.fda.gov/medical-devices/guidance-documents-medical-devices-and-radiation-emitting-products/class-ii-special-controls-documents">special controls</a>, requirements in a device specific <a
            href="https://www.fda.gov/medical-devices/overview-device-regulation/regulatory-controls">classification regulation</a>, or adherence to device specific guidance recommendation applicable for your medical device.</p>
    </ng-container>
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">
            <p>In the text box below, please address or provide information demonstrating compliance with any applicable special controls, requirements in a device specific classification regulation, or adherence to device specific guidance recommendations for the Performance Testing.</p>
            <p>For each specific special control, regulation, or guidance recommendation applicable to your device: Please list the special control, regulation, or recommendation and cite the attachment(s) and page number(s) where it was addressed.</p>
            <p>Please use the primary product code you provided in the Classification section to determine whether a device specific guidance exists for your device. Type “N/A” if no device specific guidance, regulation, or special controls exist for your device type. If you type “N/A,” and special controls, a regulation, or a device specific guidance exists that requests information covered by this review section, the time-line for review of your file may be affected.</p>
            <mat-form-field appearance="fill">
                <mat-label>Information</mat-label>
                <textarea matInput formControlName="controlsSummary" rows="5"></textarea>
            </mat-form-field>

            <p>If you choose to use an alternative approach in comparison to what is stated in the applicable guidance or special controls, please provide a rationale for this alternative approach below.</p>
            <mat-form-field appearance="fill">
                <mat-label>Alternative Approaches</mat-label>
                <textarea matInput formControlName="alternativeSummary" rows="5"></textarea>
            </mat-form-field>
        </form>
    </ng-container>
</app-step>
