export const environment = {
    production: true,
    cognito: {
        Auth: {
            Cognito: {
                userPoolId: 'us-east-1_8t9I8CiaJ',
                userPoolClientId: '6v6qub7i5cmlbundauts99cesc'
            }
        }
    },
    apiServer: "/api",
    // basePath: 'http://localhost:4200/api'
    basePath: 'https://nebula-staging.harbinger.tech/api'
};
