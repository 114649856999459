import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { environment } from 'app-env';

import { AuthenticationService } from "src/app/services/authentication.service";
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard {

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
    }

    private isProduction(): boolean {
        return environment.production;
    }

    private async isAuthenticated(): Promise<boolean> {
        return await this.authenticationService.isAuthenticated();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        // Bypass the authentication check in development mode.
        if (!this.isProduction()) {
            return true;
        }

        const isAuth = await this.isAuthenticated();
        if (!isAuth) {
            this.router.navigate(['/login']);
        }
        return isAuth;
    }

    async canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.canActivate(childRoute, state); // Use the same logic as canActivate
    }
}
