<app-step title="Profile">
    <ng-container subtitle>
        <p>Please provide contact information to be included in the submission to the FDA.</p>
    </ng-container>
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">

            <h4>Contact Information</h4>

            <mat-form-field>
                <mat-label>Title</mat-label>
                <mat-select formControlName="title">
                    <mat-option value="Mr.">Mr.</mat-option>
                    <mat-option value="Mrs.">Mrs.</mat-option>
                    <mat-option value="Ms.">Ms.</mat-option>
                    <mat-option value="Dr.">Dr.</mat-option>
                    <mat-option value="Prof.">Prof.</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('title')?.hasError('required')">Title is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>First Name</mat-label>
                <input matInput formControlName="firstName">
                <mat-error *ngIf="formGroup.get('firstName')?.hasError('required')">First Name is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="lastName">
                <mat-error *ngIf="formGroup.get('lastName')?.hasError('required')">Last Name is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>E-mail</mat-label>
                <input matInput placeholder="JDoe@lakeside.life" formControlName="email">
                <mat-error *ngIf="formGroup.get('email')?.hasError('required')">E-mail is required</mat-error>
                <mat-error *ngIf="formGroup.get('email')?.hasError('email')">E-mail is invalid</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Phone Number</mat-label>
                <input matInput placeholder="555-555-5555" formControlName="phone">
                <mat-error *ngIf="formGroup.get('phone')?.hasError('required')">Phone is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Occupation</mat-label>
                <input matInput formControlName="occupation">
                <mat-error *ngIf="formGroup.get('occupation')?.hasError('required')">Occupation is required</mat-error>
            </mat-form-field>

            <h4>Company Information</h4>

            <mat-form-field>
                <mat-label>Company Name</mat-label>
                <input matInput formControlName="companyName">
                <mat-error
                    *ngIf="formGroup.get('companyName')?.hasError('required')">Company Name is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Address 1</mat-label>
                <input matInput formControlName="address1">
                <mat-error *ngIf="formGroup.get('address1')?.hasError('required')">Address is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Address 2</mat-label>
                <input matInput formControlName="address2">
                <mat-error *ngIf="formGroup.get('address2')?.hasError('required')">Address is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>City</mat-label>
                <input matInput formControlName="city">
                <mat-error *ngIf="formGroup.get('city')?.hasError('required')">City is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                    <mat-option *ngFor="let state of STATES" [value]="state.abbreviation">{{ state.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('state')?.hasError('required')">State is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Zip</mat-label>
                <input matInput placeholder="01234" formControlName="zip">
                <mat-error *ngIf="formGroup.get('zip')?.hasError('required')">Zip is required</mat-error>
            </mat-form-field>

            <mat-form-field>
                <mat-label>Country</mat-label>
                <mat-select formControlName="country">
                    <mat-option *ngFor="let country of COUNTRIES"
                                [value]="country.abbreviation">{{ country.name }}</mat-option>
                </mat-select>
                <mat-error *ngIf="formGroup.get('country')?.hasError('required')">Country is required</mat-error>
            </mat-form-field>
        </form>
    </ng-container>
</app-step>

<div class="footer">
    <button mat-raised-button color="primary" [routerLink]="['/submission']">Save</button>
    <button mat-stroked-button [routerLink]="['/submission']">Cancel</button>
</div>
