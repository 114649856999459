import { CommonModule } from "@angular/common";
import { Component } from '@angular/core';
import { RouterModule } from "@angular/router";


@Component({
    selector: 'app-root',
    standalone: true,
    template: `<router-outlet></router-outlet>`,
    imports: [
        CommonModule,
        RouterModule,
    ]
})
export class AppComponent {
}
