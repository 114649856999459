<mat-card>
    <mat-card-header>
        <mat-card-title>{{ title }}</mat-card-title>
        <mat-card-subtitle>
            <ng-content select="[subtitle]"></ng-content>
        </mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
        <ng-content select="[content]"></ng-content>
    </mat-card-content>
   <mat-card-footer>
        <ng-content select="[footer]"></ng-content>
    </mat-card-footer>
</mat-card>
