import { Component } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { DeactivationService } from 'src/app/services/deactivation.service';
import { Observable } from "rxjs";
import { DragDropComponent } from "src/app/components/widgets/drag-drop/drag-drop.component";
import { GuidanceComponent, Resource } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { SubmissionService } from "src/app/services/submission.service";
import { UploadedFile } from "src/app/backend";

@Component({
    selector: 'app-performance-summary',
    templateUrl: './performance-summary.component.html',
    styleUrls: ['./performance-summary.component.scss'],
    standalone: true,
    imports: [
        DragDropComponent,
        GuidanceComponent,
        MatButtonModule,
        RouterModule,
        StepComponent
    ]
})
export class PerformanceSummaryComponent implements Deactivatable {

    protected resourceBundlePerformanceSummary: Resource[] = [{
        icon: 'info',
        name: '21 CFR 807.92(b)',
        path: 'https://www.accessdata.fda.gov/scripts/cdrh/cfdocs/cfcfr/cfrsearch.cfm?fr=807.92'
    }];

    public constructor(
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService
    ) {
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(false, (submission: any) => submission);
    }

    protected get briefFiles(): Set<UploadedFile> {
        return new Set();
    }

    protected uploadBriefFiles(files: Set<File>) {
        const summary = this.submissionService.selectedSubmission.performanceTesting;
        // summary. = files;
        this.submissionService.selectedSubmission.performanceTesting;
    }

    protected deleteBriefFiles(files: Set<File>) {

    }

    protected get summaryFiles(): Set<UploadedFile> {
        return new Set();
    }

    protected uploadSummaryFiles(files: Set<File>) {
        const summary = this.submissionService.selectedSubmission.performanceTesting;
        // summary.summaryFiles = files;
        this.submissionService.selectedSubmission.performanceTesting = summary;
    }

    protected deleteSummaryFiles(files: Set<File>) {

    }

    protected get conclusionFiles(): Set<UploadedFile> {
        return new Set();
    }

    protected uploadConclusionFiles(files: Set<File>) {
        const summary = this.submissionService.selectedSubmission.performanceTesting!;
        // summary.conclusionFiles = files;
        this.submissionService.selectedSubmission.performanceTesting = summary;
    }

    protected deleteConclusionFiles(files: Set<File>) {

    }
}
