import { CommonModule } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { AmplifyAuthenticatorModule } from "@aws-amplify/ui-angular";
import { SignUpInput, signUp } from "@aws-amplify/auth";
import { RoutePath } from "src/app/app.routes";

import { MATERIAL_MODULES } from "src/app/material.imports";
import { AuthenticationService } from "src/app/services/authentication.service";


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        ...MATERIAL_MODULES,
        AmplifyAuthenticatorModule,
    ]
})
export class LoginComponent implements OnInit {

    public formFields = {
        signUp: {
            email: {
                order:1,
                isRequired: true
            },
            given_name: {
                order: 2,
                label: 'First Name:',
                placeholder: 'Enter your first name',
                isRequired: true
            },
            family_name: {
                order: 3,
                label: 'Last Name:',
                placeholder: 'Enter your last name',
                isRequired: true
            },
            password: {
                order: 6
            },
            confirm_password: {
                order: 7
            }
        }
    }

    public constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {
    }

    ngOnInit(): void {
        this.checkAuthentication();
    }

    services = {
        // Override the Amplify signUp method to add custom validation.
        async handleSignUp(input: SignUpInput) {
            let { username, password, options } = input;
            if (!username.endsWith('@lakeside.life')) {
                throw new Error('Invalid email');
            }
            return signUp({
                username,
                password,
                options
            });
        },
    };

    private checkAuthentication(): void {
        this.authenticationService.isAuthenticated$.subscribe((isAuthenticated: boolean): void => {
            if (isAuthenticated) {
                this.router.navigate([`/${RoutePath.DASHBOARD}`]);
            }
        });
    }
}
