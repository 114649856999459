import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import {
    PagePredicateDevice,
    PredicateDevice,
    PredicateDeviceControllerService,
    SummaryUrl
} from "src/app/backend";


@Injectable({
    providedIn: 'root'
})
export class PredicateDeviceService {

    constructor(
        private predicateDeviceControllerService: PredicateDeviceControllerService,
    ) {
    }

    public fetchDeviceSummaryUrl(deviceId: string): Observable<SummaryUrl> {
        return this.predicateDeviceControllerService.fetchSubmissionSummaryUrl(deviceId);
    }

    public fetchDecisionSummaryUrl(deviceId: string): Observable<SummaryUrl> {
        return this.predicateDeviceControllerService.fetchDecisionSummaryUrl(deviceId);
    }

    public fetchPredicateDevices(
        predicateDevice?: Partial<PredicateDevice>,
        page?: number,
        size?: number,
        sort?: Array<string>
    ): Observable<PagePredicateDevice> {
        return this.predicateDeviceControllerService.fetchPredicateDevices(predicateDevice?.deviceName, predicateDevice?.reviewAdviseComm, predicateDevice?.decisionDate, page, size, sort);
    }

    public toSubmissionString(id: string): string {
        switch (id) {
            case "1":
                return "510(k)";
            case "2":
                return "PMA";
            case "3":
                return "Contact ODE";
            case "4":
                return "510(k) Exempt";
            case "6":
                return "Humanitarian Device Exemption";
            case "7":
                return "Enforcement Discretion";
            case "8":
                return "Emergency Use Authorization";
        }
        return "Unknown";
    }
}
