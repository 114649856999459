<app-step title="Predicate Device">
    <ng-container subtitle>
        <p>In this section, you will determine if an appropriate product classification exists for your medical device, as well as identify any predicate devices that have been approved by the FDA.</p>
        <p>The FDA defines a predicate device as a legally marketed device(s) which is used as a point of equivalence / comparison for new medical devices seeking approval through FDA's 510(k) premarket clearance pathway. A claim of substantial equivalence does not mean the devices(s) must be identical.</p>
        <p>To begin your search, please enter the requested information in the respective fields provided. Please refer to the Guidance tabs for additional information on each field.</p>
    </ng-container>
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">

            <app-guidance>
                <ng-template>
                    <p>The <strong>Decision Date</strong> is the date that the FDA made a decision on the predicate device. Using a predicate device that has an older decision date has the potential to be rejected as the FDA may rule that the predicate device doesn't meet current standards.</p>
                </ng-template>
            </app-guidance>
            <mat-form-field appearance="fill">
                <mat-label>Display Devices with a Decision Date After</mat-label>
                <input matInput formControlName="decisionDate" [matDatepicker]="picker" placeholder="MM/DD/YYYY">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <app-guidance>
                <ng-template>
                    <p>The <strong>Filter by Device Name</strong> is where you will enter a device name or specific analyte. As you begin typing, the search field will begin to populate with Predicate Device Names based on your entry information.</p>
                    <p>The Device Name uses the format of a noun that is usually followed by one or more adjectives, separated by commas. Therefore, the first word in the device name is usually the best search parameter. For example, to find a specific pump, search on the word 'pump' to retrieve all products with the word 'pump' in their name.</p>
                </ng-template>
            </app-guidance>
            <mat-form-field appearance="fill">
                <mat-label>Filter by Device Name</mat-label>
                <input matInput formControlName="deviceName" (keydown.enter)="$event.preventDefault()">
            </mat-form-field>

            <app-guidance>
                <ng-template>
                    <p>The <strong>Product Classification / Review Panel</strong> is where you identify the Product Classification / Review Panel of your medical device. Compass will populate the field with the selection you indicated in the Device Registration section. The predicate device search will be based on this selection, if you so choose, you broaden your search by leaving this field blank. However, it is highly recommended that the Product Classification / Review Panel of your medical device and that of the predicate device you choose match.</p>
                </ng-template>
            </app-guidance>
            <mat-form-field appearance="fill">
                <mat-label>Filter by Product Classification / Review Panel</mat-label>
                <mat-select formControlName="reviewPanel">
                    <mat-option *ngFor="let opt of classificationPanels"
                                [value]="opt">{{ opt.description }}</mat-option>
                </mat-select>
            </mat-form-field>
            <p *ngIf="isNotMatchingProductClassification()">You've selected the {{ deviceRegistration.classificationPanel }} during the Device Registration. Changing the filter would display devices that may not match your device's classification. It is highly recommended that your device's product classification matches your predicate device.</p>
        </form>

        <table class="w-100p" mat-table matSort [dataSource]="predicateDevicesDataSource">

            <ng-container matColumnDef="handle">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-button color="secondary" (click)="addPredicateDevice(row)"
                            [disabled]="this.isSelectedPredicateDevice(row)">Add</button>
                </td>
            </ng-container>

            <ng-container matColumnDef="deviceName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Available Predicate Device Name</th>
                <td mat-cell *matCellDef="let device">{{ device["deviceName"] }}</td>
            </ng-container>

            <ng-container matColumnDef="kNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>K Number</th>
                <td mat-cell *matCellDef="let device">{{ device["kNumber"] }}</td>
            </ng-container>

            <ng-container matColumnDef="decisionDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Decision Date</th>
                <td mat-cell *matCellDef="let device">{{ device["decisionDate"] | date:'MMMM d, y' }}</td>
            </ng-container>

            <ng-container matColumnDef="productCode">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Product Code</th>
                <td mat-cell *matCellDef="let device">{{ device["productCode"] }}</td>
            </ng-container>

            <ng-container matColumnDef="classification">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Classification</th>
                <td mat-cell *matCellDef="let device">{{ device["classification"] }}</td>
            </ng-container>

            <ng-container matColumnDef="reviewAdviseComm">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Review Panel</th>
                <td mat-cell *matCellDef="let device">{{ device["reviewAdviseComm"] }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let device">
                    <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">View <mat-icon
                        fontIcon="expand_more"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item
                                (click)="openDeviceSummary(device['kNumber'])">View Decision Summary</button>
                        <button mat-menu-item
                                (click)="openDecisionSummary(device['kNumber'])">View Device Summary</button>
                        <button mat-menu-item
                                (click)="openProductClassification(device['productCode'])">View Product Classification</button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell"
                    [colSpan]="this.displayedColumns.length">{{ this.isLoadingData ? "Loading Data..." : "No data matching filter" }}</td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator class="mb-25" [pageSizeOptions]="[5, 10, 15, 20]" showFirstLastButtons></mat-paginator>

        <div class="mb-50">
            <table mat-table [dataSource]="selectedPredicateDeviceDataSource"
                   *ngIf="selectedPredicateDeviceDataSource.data.length > 0">

                <ng-container matColumnDef="handle">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-button color="secondary" (click)="removePredicateDevice(row)">Remove</button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="deviceName">
                    <th mat-header-cell *matHeaderCellDef>Selected Predicate Device Name</th>
                    <td mat-cell *matCellDef="let device">{{ device["deviceName"] }}</td>
                </ng-container>

                <ng-container matColumnDef="kNumber">
                    <th mat-header-cell *matHeaderCellDef>K Number</th>
                    <td mat-cell *matCellDef="let device">{{ device["kNumber"] }}</td>
                </ng-container>

                <ng-container matColumnDef="decisionDate">
                    <th mat-header-cell *matHeaderCellDef>Decision Date</th>
                    <td mat-cell *matCellDef="let device">{{ device["decisionDate"] | date:'MMMM d, y'  }}</td>
                </ng-container>

                <ng-container matColumnDef="productCode">
                    <th mat-header-cell *matHeaderCellDef>Product Code</th>
                    <td mat-cell *matCellDef="let device">{{ device["productCode"] }}</td>
                </ng-container>

                <ng-container matColumnDef="classification">
                    <th mat-header-cell *matHeaderCellDef>Classification</th>
                    <td mat-cell *matCellDef="let device">{{ device["classification"] }}</td>
                </ng-container>

                <ng-container matColumnDef="reviewAdviseComm">
                    <th mat-header-cell *matHeaderCellDef>Review Panel</th>
                    <td mat-cell *matCellDef="let device">{{ device["reviewAdviseComm"] }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let device">
                        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">View <mat-icon
                            fontIcon="expand_more"></mat-icon></button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item
                                    (click)="openDeviceSummary(device['kNumber'])">View Decision Summary</button>
                            <button mat-menu-item
                                    (click)="openDecisionSummary(device['kNumber'])">View Device Summary</button>
                            <button mat-menu-item
                                    (click)="openProductClassification(device['productCode'])">View Product Classification</button>
                        </mat-menu>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <!--            <div *ngIf="selectedPredicateDeviceDataSource.data.length === 0">No Predicate Devices Have Been Selected</div>-->
        </div>
    </ng-container>
</app-step>
