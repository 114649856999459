import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bytesToKilobytes',
    standalone: true
})
export class BytesToKilobytesPipe implements PipeTransform {

    transform(bytes: number, ...args: unknown[]): unknown {
        if (bytes === 0) return '0 KB';

        const k = 1024;
        const kilobytes = bytes / k;

        return `${kilobytes.toFixed(2)} KB`;
    }
}
