import { CommonModule } from "@angular/common";
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";

import { GuidanceComponent } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { DeviceRegistration, Submission } from "src/app/backend";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { ClassificationPanel } from "src/app/models/classificationPanel";
import { technologies } from "src/app/models/technology";
import { DeactivationService } from "src/app/services/deactivation.service";
import { LoadingService } from "src/app/services/loading-service.service";
import { ProductClassificationService } from "src/app/services/product-classification.service";
import { SubmissionService } from "src/app/services/submission.service";

import { finalize, map, Observable, startWith } from 'rxjs';


@Component({
    selector: 'app-device-registration',
    templateUrl: './device-registration.component.html',
    styleUrls: ['./device-registration.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        GuidanceComponent,
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        StepComponent
    ]
})
export class DeviceRegistrationComponent implements OnInit, Deactivatable {

    protected classificationPanels: ClassificationPanel[] = [];

    protected resourceBundle = {
        deviceType: [
            {
                icon: 'info',
                name: 'Deciding When to Submit a 510(k) for a Change to an Existing Device',
                path: 'https://www.fda.gov/regulatory-information/search-fda-guidance-documents/deciding-when-submit-510k-change-existing-device'
            }
        ]
    };

    protected technologies = technologies;

    public formGroup = new FormGroup({
        productName: new FormControl<string>('', Validators.required),
        description: new FormControl<string>('', Validators.required),
        isInstrument: new FormControl<boolean>(false, Validators.required),
        isAssay: new FormControl<boolean>(false, Validators.required),
        technology: new FormControl<string>('', Validators.required),
        analysisType: new FormControl<string>('', Validators.required),
        specimenType: new FormControl<string>('', Validators.required),
        clinicalUse: new FormControl<string>('', Validators.required),
        clinicalPurpose: new FormControl<string>('', Validators.required),
        intendedGender: new FormControl<string>('', Validators.required),
        intendedPopulation: new FormControl<Array<string>>([], Validators.required),
        setting: new FormControl<Array<string>>([], Validators.required),
        classificationPanel: new FormControl<string>('', Validators.required),
    });

    public constructor(
        private productClassificationService: ProductClassificationService,
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService,
        private loadingService: LoadingService
    ) {
    }

    public ngOnInit(): void {
        this.fetchClassificationPanels();
        this.loadFormData();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(this.formGroup.dirty, (submission: Submission) => this.patchSubmission(submission));
    }

    private fetchClassificationPanels(): void {
        this.loadingService.show();
        this.productClassificationService.fetchClassificationPanels$()
            .pipe(
                finalize(() => this.loadingService.hide())
            )
            .subscribe((classificationPanels: ClassificationPanel[]) => {
                this.classificationPanels = classificationPanels;
            });
    }

    private loadFormData() {
        this.formGroup.patchValue(this.submissionService.selectedSubmission?.overview?.deviceRegistration ?? {});
    }

    public patchSubmission(submission: Submission): Submission {
        return {
            ...submission,
            overview: {
                ...submission.overview,
                deviceRegistration: this.formGroup.value as DeviceRegistration,
            }
        } as Submission;
    }

    protected get filteredSpecimenTypeOptions$() {
        const specimenTypeOptions: string[] = ['Anterior Nasal Swab', 'Breath', 'Cerebral Spinal Fluid', 'Mid-Turbinate Swap', 'Nasal Fluid', 'Nasopharyngeal Swab', 'Plasma', 'Saliva', 'Serum', 'Throat Swab', 'Tissue', 'Urine', 'Whole Blood'];
        return this.generateFilteredOptions$('specimenType', specimenTypeOptions);
    }

    protected get filteredClinicalPurposeOptions$() {
        const clinicalPurposeOptions: string[] = ['Disease Screening', 'Disease Diagnosis', 'Disease Prognosis', 'Monitoring'];
        return this.generateFilteredOptions$('clinicalPurpose', clinicalPurposeOptions);
    }

    private generateFilteredOptions$(controlName: string, options: string[]): Observable<string[]> {
        const controls = this.formGroup.controls as { [key: string]: AbstractControl };
        return controls[controlName].valueChanges
            .pipe(
                startWith(''),
                map(value => options.filter(option => option.toLowerCase().includes((value || '').toLowerCase())))
            );
    }
}
