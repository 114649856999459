import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { signOut, getCurrentUser } from '@aws-amplify/auth';
import { Hub } from "@aws-amplify/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { RoutePath } from "src/app/app.routes";
import { Profile } from "src/app/models/profile";


export enum AuthEventType {
    SignedIn = 'signedIn',
    SignInWithRedirect = 'signInWithRedirect',
    SignedOut = 'signedOut',
    // Add other relevant events here
}


@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private authenticationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router
    ) {
        this.checkCurrentAuthState();
        this.listenToAuthEvents();
        // this.authenticationSubject.next(this.isAuthenticated())
    }

    private async checkCurrentAuthState(): Promise<void> {
        try {
            await getCurrentUser();
            this.authenticationSubject.next(true);
        } catch (error) {
            this.authenticationSubject.next(false);
        }
    }

    get isAuthenticated$(): Observable<boolean> {
        return this.authenticationSubject.asObservable();
    }

    private listenToAuthEvents(): void {
        Hub.listen('auth', (data) => {
            const event = data.payload?.event as string;
            switch (event) {
                case AuthEventType.SignedIn:
                case AuthEventType.SignInWithRedirect:
                    this.authenticationSubject.next(true);
                    break;
                case AuthEventType.SignedOut:
                    this.authenticationSubject.next(false);
                    break;
                default:
                    console.log(`Unhandled event: ${event}`);
            }
        });
    }

    public logout(): void {
        signOut()
            .then(() => {
                // this.authenticationSubject.next(false);
                this.router.navigate([`/${RoutePath.LOGIN}`]);
            });
    }

    public async isAuthenticated(): Promise<boolean> {
        try {
            // Try to get the currently authenticated user
            const user = await getCurrentUser();
            return !!user;
        } catch (error) {
            return false;
        }
    }

    public async getUserId(): Promise<string> {
        const user = await getCurrentUser();
        return user.signInDetails?.loginId || '';
    }

    public get profile(): Profile {
        return {
            title: 'Mr.',
            lastName: 'Doe',
            firstName: 'John',
            email: 'jdoe@lakeside.life',
            phone: '123-456-7890',
            occupation: 'Software Engineer',
            companyName: 'Lakeside Software',
            address1: '123 Main Street',
            address2: 'Suite 100',
            city: 'Bloomfield Hills',
            state: 'MI',
            zip: '48302',
            country: 'US',
        } as Profile;
    }
}
