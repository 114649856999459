<app-step title="Welcome to Lakeside Life Science's Compass">
    <ng-container content>
        <div class="img-container"><img ngSrc="/images/LLS-Logo.jpg" height="100" width="120"/></div>
        <p>Compass is a proprietary software application that assists, guides and provides the necessary resources (templates, FDA Guidance documents and CLSI protocol references) for Users in the determination, development and execution of an IVD medical Device Regulatory Submission Strategy in preparation of an FDA submission via eSTAR.</p>
        <mat-divider></mat-divider>
        <p><em>This tool is intended to provide guidance and does not guarantee acceptance of any submission by the FDA. The FDA does not endorse the use of this tool.</em></p>
    </ng-container>
</app-step>

<app-step title="Submissions">
    <ng-container content>
        <table mat-table matSort [dataSource]="submissionDataSource" class="submissions">

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let submission">
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()"><mat-icon fontIcon="more_vert"></mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="edit(submission)"><mat-icon fontIcon="edit"></mat-icon>Edit</button>
                        <button mat-menu-item (click)="delete(submission)"><mat-icon fontIcon="delete"></mat-icon>Delete</button>
                    </mat-menu>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let submission">{{ submission.overview.deviceRegistration.productName }}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
                <td mat-cell *matCellDef="let submission">{{ submission.overview.deviceRegistration.description }}</td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="this.displayedColumns.length">{{ "No submissions available." }}</td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="submission-row" (click)="edit(element)"></tr>
        </table>
    </ng-container>

    <ng-container footer>
        <div class="actions">
            <button mat-raised-button color="primary" class="actions" (click)="createNewSubmission()">
                <mat-icon fontIcon="add_circle_outline"></mat-icon> Create New Submission
            </button>
        </div>
    </ng-container>
</app-step>
