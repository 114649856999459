export interface Protocol {
    category: string;
    clsi: string;
    title: string;
}

export enum ProtocolType {
    PRECISION = 'precision',
    ACCURACY = 'accuracy',
    LINEARITY = 'linearity',
    INTERFERING_SUBSTANCES = 'interfering_substances',
    CROSS_REACTIVITY = 'cross_reactivity',
    HOOK_EFFECT = 'hook_effect',
    SAMPLE_STABILITY = 'sample_stability',
    SENSITIVITY = 'sensitivity',
    MATRIX_COMPARISON = 'matrix_comparison',
    TRACEABILITY = 'traceability',
    CARRYOVER = 'carryover',
    TUMOR_CONTENT = 'tumor_content',
    DNA_INPUT = 'dna_input',
    NECROTIC_FRACTION = 'necrotic_fraction',
    CROSS_CONTAMINATION = 'cross_contamination',
    PROBE_SPECIFICITY = 'probe_specificity',
    ANTIGEN_CHARACTERIZATION = 'antigen_characterization',
    THERMOCYCLER_QUALIFICATION = 'thermocycler_qualification',
    DNA_RNA_EXTRACTION = 'dna_rna_extraction',
    GUARD_BAND_STUDIES = 'guard_band_studies',
    REFERENCE_INTERVAL = 'reference_interval',
    ANALYTICAL_SPECIFICITY = 'analytical_specificity',
    SPIKE_RECOVERY = 'spike_recovery',
    REPRODUCIBILITY = 'reproducibility',
    INCLUSIVITY = 'inclusivity',
    MICROBIAL_INTERFERENCE = 'microbial_interference',
    COMPETITIVE_INHIBITION = 'competitive_inhibition',
    FLEX_STUDIES = 'flex_studies',
    SIGNAL_STABILITY = 'signal_stability',
    PROBE_PHOTOSTABILITY = 'probe_photostability',
    STABILITY_STUDIES = 'stability_studies',
    SHELF_LIFE = 'shelf_life',
    STABILITY_STUDIES_OPEN = 'stability_studies_open',
    SHIPPING_STABILITY = 'shipping_stability',
    FREEZE_THAW = 'freeze_thaw'
}

export const protocolDocumentMap: Record<ProtocolType, Array<Protocol>> = {
    [ProtocolType.PRECISION]: [
        {category: 'Precision', clsi: 'EP05-A3', title: 'Evaluation of Precision Performance of Quantitative Measurement Methods'}
    ],
    [ProtocolType.ACCURACY]: [
        {category: 'Accuracy', clsi: 'EP09-A3', title: 'Measurement Procedure Comparison and Bias Estimation Using Patient Samples'},
        {category: 'Accuracy', clsi: 'EP15-A3', title: 'User Verification of Precision and Estimation of Bias'}
    ],
    [ProtocolType.LINEARITY]: [
        {category: 'Linearity', clsi: 'EP06-A', title: 'Evaluation of the Linearity of Quantitative Measurement Procedures: A Statistical Approach'}
    ],
    [ProtocolType.INTERFERING_SUBSTANCES]: [
        {category: 'Interfering Substances', clsi: 'EP07-A2', title: 'Interference Testing in Clinical Chemistry'}
    ],
    [ProtocolType.CROSS_REACTIVITY]:[
        {category: 'Cross Reactivity', clsi: 'I/LA31-A', title: 'Immunoassay Interference by Endogenous Antibodies'}
    ],
    [ProtocolType.HOOK_EFFECT]: [
        {category: 'Hook Effect/Pro-zone', clsi: 'Not Specifically Covered', title: 'Refer to immunoassay validation guidelines generally'}
    ],
    [ProtocolType.SAMPLE_STABILITY]: [
        {category: 'Sample Stability', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ],
    [ProtocolType.SENSITIVITY]: [
        {category: 'Sensitivity/LOD/LOB/LOQ', clsi: 'EP17-A2', title: 'Evaluation of Detection Capability for Clinical Laboratory Measurement Procedures'}
    ],
    [ProtocolType.MATRIX_COMPARISON]:[
        {category: 'Matrix Comparison', clsi: 'EP14-A3', title: 'Evaluation of Commutability of Processed Samples'}
    ],
    [ProtocolType.TRACEABILITY]: [
        {category: 'Traceability (Standarization/Controls)', clsi: 'EP23-A', title: 'Metrological Traceability and its Implementation'}
    ],
    [ProtocolType.CARRYOVER]: [
        {category: 'Carryover', clsi: 'EP10-A3', title: 'Preliminary Evaluation of Quantitative Clinical Laboratory Measurement Procedures'}
    ],
    [ProtocolType.TUMOR_CONTENT]: [
        {category: 'LOD: Tumor Content', clsi: 'MM06-A2', title: 'Quantitative Molecular Methods for Infectious Diseases'}
    ],
    [ProtocolType.DNA_INPUT]: [
        {category: 'LOD: DNA Input', clsi: 'MM06-A2', title: 'Quantitative Molecular Methods for Infectious Diseases'}
    ],
    [ProtocolType.NECROTIC_FRACTION]: [
        {category: 'LOD: Necrotic Fraction', clsi: 'MM06-A2', title: 'Quantitative Molecular Methods for Infectious Diseases'}
    ],
    [ProtocolType.CROSS_CONTAMINATION]: [
        {category: 'Cross Contamination', clsi: 'MM06-A2', title: 'Quantitative Molecular Methods for Infectious Diseases'},
        {category: 'Cross Contamination', clsi: 'QMS24', title: 'Using Proficiency Testing to Improve the Clinical Laboratory'}
    ],
    [ProtocolType.PROBE_SPECIFICITY]: [
        {category: 'Probe Molecular Specificity', clsi: 'MM03-A2', title: 'Molecular Diagnostic Methods for Infectious Diseases'}
    ],
    [ProtocolType.ANTIGEN_CHARACTERIZATION]: [
        {category: 'Antigen/Antibody Characterization', clsi: 'I/LA25-A', title: 'Validation of Immunoassays for Determination of Endogenous Analytes'}
    ],
    [ProtocolType.THERMOCYCLER_QUALIFICATION]: [
        {category: 'Thermocycler Qualification', clsi: 'MM03-A2', title: 'Molecular Diagnostic Methods for Infectious Diseases'}
    ],
    [ProtocolType.DNA_RNA_EXTRACTION]: [
        {category: 'DNA/RNA Extraction Method Qual', clsi: 'MM13-A', title: 'Collection, Transport, Preparation, and Storage of Specimens for Molecular Methods'}
    ],
    [ProtocolType.GUARD_BAND_STUDIES]: [
        {category: 'Guard Band Studies', clsi: 'Not Specifically Covered', title: 'Refer to General Validation Guidelines'}
    ],
    [ProtocolType.REFERENCE_INTERVAL]: [
        {category: 'Reference Interval/ULN/Reference Range', clsi: 'EP28-A3C', title: 'Defining, Establishing, and Verifying Reference Intervals in the Clinical Laboratory'}
    ],
    [ProtocolType.ANALYTICAL_SPECIFICITY]: [
        {category: 'Analytical Specificity', clsi: 'EP07-A2', title: 'Interference Testing in Clinical Chemistry'}
    ],
    [ProtocolType.SPIKE_RECOVERY]:[
        {category: 'Spike Recoery', clsi: 'EP07-A2', title: 'Interference Testing in Clinical Chemistry'}
    ],
    [ProtocolType.REPRODUCIBILITY]: [
        {category: 'Reproducibility', clsi: 'EP05-A3', title: 'Evaluation of Precision Performance of Quantitative Measurement Methods'}
    ],
    [ProtocolType.INCLUSIVITY]: [
        {category: 'Inclusivity', clsi: 'MM03-A2', title: 'Molecular Diagnostic Methods for Infectious Diseases'}
    ],
    [ProtocolType.MICROBIAL_INTERFERENCE]: [
        {category: 'Microbial Interference', clsi: 'MM03-A2', title: 'Molecular Diagnostic Methods for Infectious Diseases'}
    ],
    [ProtocolType.COMPETITIVE_INHIBITION]: [
        {category: 'Completitive Inhibition', clsi: 'Not Specifically Covered', title: 'Refer to General Immunoassay Validation Guidelines'}
    ],
    [ProtocolType.FLEX_STUDIES]: [
        {category: 'FMEA (Risk) - FLEX Studies', clsi: 'Not Specifically Covered', title: 'Refer to General Immunoassay Validation Guidelines'}
    ],
    [ProtocolType.SIGNAL_STABILITY]: [
        {category: 'Signal Stability', clsi: 'Not Specifically Covered', title: 'Refer to General Immunoassay Validation Guidelines'}
    ],
    [ProtocolType.PROBE_PHOTOSTABILITY]: [
        {category: 'Probe Photostability', clsi: 'Not Specifically Covered', title: 'Refer to General Immunoassay Validation Guidelines'}
    ],
    [ProtocolType.STABILITY_STUDIES]: [
        {category: 'Stability Studies', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ],
    [ProtocolType.SHELF_LIFE]: [
        {category: 'Stability Studies: Shelf Life', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ],
    [ProtocolType.STABILITY_STUDIES_OPEN]:[
        {category: 'Stability Studies: Open Vial/Open Package', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ],
    [ProtocolType.SHIPPING_STABILITY]: [
        { category: 'Shipping Stability', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ],
    [ProtocolType.FREEZE_THAW]: [
        {category: 'Freeze-Thaw', clsi: 'EP25-A', title: 'Evaluation of Stability of In Vitro Diagnostic Reagents'}
    ]
};

export interface Experiment {
    viewValue: string;
    protocols: Array<Protocol>;
}

export const technologies: Record<string, Experiment> = {
    'chemistry': {
        viewValue: 'Chemistry',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.HOOK_EFFECT],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'immunoassays': {
        viewValue: 'Immunoassays',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.HOOK_EFFECT],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW],
        ]
    },
    'pcr': {
        viewValue: 'PCR/RT-PCR',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.PROBE_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.THERMOCYCLER_QUALIFICATION],
            ...protocolDocumentMap[ProtocolType.DNA_RNA_EXTRACTION],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.INCLUSIVITY],
            ...protocolDocumentMap[ProtocolType.COMPETITIVE_INHIBITION],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'ctcs': {
        viewValue: 'CTCs',
        protocols: [
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.SPIKE_RECOVERY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'lfa': {
        viewValue: 'LFA',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.HOOK_EFFECT],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.INCLUSIVITY],
            ...protocolDocumentMap[ProtocolType.MICROBIAL_INTERFERENCE],
            ...protocolDocumentMap[ProtocolType.COMPETITIVE_INHIBITION],
            ...protocolDocumentMap[ProtocolType.FLEX_STUDIES],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'ihc': {
        viewValue: 'IHC',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.ANALYTICAL_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'ngs': {
        viewValue: 'NGS',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.TUMOR_CONTENT],
            ...protocolDocumentMap[ProtocolType.DNA_INPUT],
            ...protocolDocumentMap[ProtocolType.NECROTIC_FRACTION],
            ...protocolDocumentMap[ProtocolType.CROSS_CONTAMINATION],
            ...protocolDocumentMap[ProtocolType.PROBE_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.THERMOCYCLER_QUALIFICATION],
            ...protocolDocumentMap[ProtocolType.DNA_RNA_EXTRACTION],
            ...protocolDocumentMap[ProtocolType.GUARD_BAND_STUDIES],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY]
        ]
    },
    'mass_spec': {
        viewValue: 'Mass Spec',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.MATRIX_COMPARISON],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.ANALYTICAL_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'flow_cytometry': {
        viewValue: 'Flow Cytometry',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.CARRYOVER],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.ANALYTICAL_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'hematology': {
        viewValue: 'Hematology',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.MATRIX_COMPARISON],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.CARRYOVER],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'automated_microscopy': {
        viewValue: 'Automated Microscopy',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.ACCURACY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.ANTIGEN_CHARACTERIZATION],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'breath_analyzer': {
        viewValue: 'Breath Analyzer',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.CROSS_REACTIVITY],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'tissue_microarrays': {
        viewValue: 'Tissue Microarrays',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
        ]
    },
    'fish': {
        viewValue: 'FISH',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.SAMPLE_STABILITY],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.PROBE_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.ANALYTICAL_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.PROBE_PHOTOSTABILITY],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'electrophoresis': {
        viewValue: 'Gel/Capillary Electrophoresis',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.CARRYOVER],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.ANALYTICAL_SPECIFICITY],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
            ...protocolDocumentMap[ProtocolType.SHIPPING_STABILITY],
            ...protocolDocumentMap[ProtocolType.FREEZE_THAW]
        ]
    },
    'glucose_meter': {
        viewValue: 'Glucose Meter',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
        ]
    },
    'poc_cholesterol': {
        viewValue: 'POC Cholesterol',
        protocols: [
            ...protocolDocumentMap[ProtocolType.PRECISION],
            ...protocolDocumentMap[ProtocolType.LINEARITY],
            ...protocolDocumentMap[ProtocolType.INTERFERING_SUBSTANCES],
            ...protocolDocumentMap[ProtocolType.SENSITIVITY],
            ...protocolDocumentMap[ProtocolType.TRACEABILITY],
            ...protocolDocumentMap[ProtocolType.REFERENCE_INTERVAL],
            ...protocolDocumentMap[ProtocolType.REPRODUCIBILITY],
            ...protocolDocumentMap[ProtocolType.SHELF_LIFE],
            ...protocolDocumentMap[ProtocolType.STABILITY_STUDIES_OPEN],
        ]
    }
};
