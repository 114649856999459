import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { catchError, Observable, of, take } from "rxjs";
import { ClassificationPanel } from "src/app/models/classificationPanel";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import {
    PageProductClassification,
    ProductClassification,
    ProductClassificationControllerService
} from "src/app/backend";


@Injectable({
    providedIn: 'root'
})
export class ProductClassificationService {

    constructor(
        private productClassificationControllerService: ProductClassificationControllerService,
        private errorHandlerService: ErrorHandlerService
    ) {
    }

    public fetchProductClassifications(
        productClassification: Partial<ProductClassification> = {} as Partial<ProductClassification>,
        page: number = 0,
        size: number = 10,
        sort: string = '',
    ): Observable<PageProductClassification> {
        const productCode = productClassification.productCode;
        return this.productClassificationControllerService.fetchProductClassifications(productCode, page, size, [sort]);
    }

    /**
     * Returns a list of classification panels.
     *
     * https://www.fda.gov/medical-devices/classify-your-medical-device/device-classification-panels
     */
    public fetchClassificationPanels$(): Observable<ClassificationPanel[]> {
        return of([
            {"medicalSpecialty": 75, "regulationCode": 862, "abbr": "CH", "description": "Chemistry"},
            {"medicalSpecialty": 81, "regulationCode": 864, "abbr": "HE", "description": "Hematology"},
            {"medicalSpecialty": 82, "regulationCode": 866, "abbr": "IM", "description": "Immunology"},
            {"medicalSpecialty": 83, "regulationCode": 866, "abbr": "MI", "description": "Microbiology"},
            {"medicalSpecialty": 88, "regulationCode": 864, "abbr": "PA", "description": "Pathology"},
            {"medicalSpecialty": 0, "regulationCode": 0, "abbr": " ", "description": "Unknown"}
        ] as ClassificationPanel[])
            .pipe(
                // These pipes aren't doing anything at the moment, but aren't hurting anything either. This will be
                // useful for when we move this hardcoded data into the backend.
                take(1),
                catchError((error: HttpErrorResponse) => {
                    this.errorHandlerService.handleError(error);
                    this.errorHandlerService.showError(error);
                    return of([]);
                }),
            )
    }
}
