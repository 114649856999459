/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DeviceRegistration { 
    productName?: string;
    description?: string;
    isInstrument?: boolean;
    isAssay?: boolean;
    technology?: string;
    analysisType?: string;
    specimenType?: string;
    clinicalUse?: string;
    clinicalPurpose?: string;
    intendedGender?: string;
    intendedPopulation?: Array<string>;
    setting?: Array<string>;
    classificationPanel?: string;
    deviceClassification?: DeviceRegistration.DeviceClassificationEnum;
}
export namespace DeviceRegistration {
    export type DeviceClassificationEnum = 'UNKNOWN' | 'CLASS_1' | 'CLASS_2' | 'CLASS_3';
    export const DeviceClassificationEnum = {
        Unknown: 'UNKNOWN' as DeviceClassificationEnum,
        Class1: 'CLASS_1' as DeviceClassificationEnum,
        Class2: 'CLASS_2' as DeviceClassificationEnum,
        Class3: 'CLASS_3' as DeviceClassificationEnum
    };
}


