<app-step title="Submission Strategy">
    <ng-container subtitle>
        <p>The Submission Strategy is a compilation of the information that you have entered, which includes your Predicate Device choices. Based on your inputs, Compass will make a Regulatory Submission Strategy recommendation.</p>
    </ng-container>
    <ng-container content>
        <app-summary [submission]="submission"></app-summary>

        <!--    <ul>-->
        <!--        <li *ngIf="displayPresubmission">-->
        <!--            <p>-->
        <!--                Pre-Submission-->
        <!--                <app-guidance>-->
        <!--                    <ng-template>-->
        <!--                        <p>A Pre-Sub provides the opportunity for a submitter to obtain FDA feedback prior to an intended premarket submission.</p>-->
        <!--                        <p>The request should include specific questions regarding review issues relevant to a planned submission (e.g., questions regarding cybersecurity considerations for the device; non-clinical testing protocols; design and performance of clinical studies and acceptance criteria).</p>-->
        <!--                        <p>A Pre-Sub is appropriate when FDA’s feedback on specific questions is necessary to guide product development and/or submission preparation.</p>-->
        <!--                        <p>A Pre-Sub includes a formal written request from a submitter for feedback from FDA that is provided in the form of a formal written response or, if the submitter chooses, formal written feedback followed by a meeting in which any additional feedback or clarifications are documented in meeting minutes. Such a Pre-Sub meeting can be in-person or by teleconference as the submitter prefers.</p>-->
        <!--                        <p>Refer to Resource Section for FDA Guidance document.</p>-->
        <!--                    </ng-template>-->
        <!--                </app-guidance>-->
        <!--            </p>-->
        <!--        </li>-->
        <!--    </ul>-->

    </ng-container>
</app-step>

<!--<button mat-raised-button color="primary" *ngIf="filingType === Filing.FTKE" routerLink="/submission/introduction/cover-letter" class="mr-10">Initiate 510(K) Exempt Submission Process</button>-->
