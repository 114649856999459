import { CommonModule, NgOptimizedImage } from "@angular/common";
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule, Routes } from "@angular/router";
import { filter, map, Subject, takeUntil } from "rxjs";
import { RoutePath } from "src/app/app.routes";
import { Submission } from "src/app/backend";
import { OverviewRoutes } from "src/app/components/pages/overview/overview.routes";
import { PerformanceTestingRoutes } from "src/app/components/pages/performance-testing/performance-testing.routes";
import { MATERIAL_MODULES } from "src/app/material.imports";
import { AuthenticationService } from "src/app/services/authentication.service";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { SubmissionService } from "src/app/services/submission.service";

@Component({
    selector: 'app-sidenav-layout',
    standalone: true,
    templateUrl: './sidenav-layout.component.html',
    styleUrl: './sidenav-layout.component.scss',
    imports: [
        CommonModule,
        NgOptimizedImage,
        RouterModule,
        ...MATERIAL_MODULES,
    ]
})
export class SidenavLayoutComponent {

    protected readonly routePaths = RoutePath;
    protected readonly navItems: Record<string, Routes> = {
        "Design": OverviewRoutes,
        "Execute": PerformanceTestingRoutes
    }

    protected username: string = 'Unknown User';
    protected selectedSubmission: Submission | null = null;
    protected nextSectionUrl: string | null = null;
    protected childRoute: ActivatedRoute | null = null;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(
        private authenticationService: AuthenticationService,
        private submissionService: SubmissionService,
        private errorHandlerService: ErrorHandlerService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
    }

    public ngOnInit(): void {
        if (!this.selectedSubmission) {
            this.router.navigate([RoutePath.DASHBOARD]);
        }

        this.fetchUserDetails();
        this.fetchSelectedSubmission();
        this.updateNextSectionButtonStatus();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected isFullPageLayout(): boolean {
        // Only the Login page should have a full page layout.
        return this.router.url == `/${RoutePath.LOGIN}`;
    }
    protected async getUserId(): Promise<string> {
        return this.authenticationService.getUserId();
    }

    private fetchUserDetails(): void {
        this.authenticationService.getUserId()
            .then(userId => {
                this.username = userId;
            })
            .catch(error => {
                this.username = 'Unknown User';
                this.errorHandlerService.handleError(error);
            });
    }

    private fetchSelectedSubmission(): void {
        this.submissionService.selectedSubmission$
            .pipe(takeUntil(this.destroy$))
            .subscribe((submission: Submission): void => {
                this.selectedSubmission = submission;
            });
    }

    private updateNextSectionButtonStatus() {
        // Determine if we need to display the Next Section button by looking at the route's data section for a 'hideNextSectionButton'.
        this.router.events.pipe(
            takeUntil(this.destroy$),
            filter(event => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map(route => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter(route => route.outlet === 'primary')
        ).subscribe(route => {
            // Default to showing the button if the property does not exist.
            this.nextSectionUrl = route.snapshot.data?.['nextSectionUrl'];
            this.childRoute = route;
        });
    }

    protected hasSelectedSubmission(): boolean {
        return !!this.selectedSubmission?.overview?.deviceRegistration?.productName;
    }

    protected logout(): void {
        this.authenticationService.logout();
    }

    protected nextSection(): void {
        this.router.navigate([this.nextSectionUrl], {relativeTo: this.childRoute})
            .catch(error => this.errorHandlerService.handleError(error));
    }

}
