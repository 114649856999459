import { CommonModule, NgOptimizedImage } from "@angular/common";
import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { finalize, Subject, take } from "rxjs";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { OverviewRoutes } from "src/app/components/pages/overview/overview.routes";
import { MATERIAL_MODULES } from "src/app/material.imports";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { LoadingService } from "src/app/services/loading-service.service";
import { SubmissionService } from "src/app/services/submission.service";
import { Submission, SubmissionPage } from "src/app/backend";


@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        NgOptimizedImage,
        StepComponent,
        ...MATERIAL_MODULES
    ]
})
export class DashboardComponent implements OnInit, OnDestroy {

    protected displayedColumns: string[] = ['actions', 'name', 'description'];
    protected submissionDataSource = new MatTableDataSource<Submission>();

    protected destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private submissionService: SubmissionService,
        private dialog: MatDialog,
        private loadingService: LoadingService,
        private errorHandlerService: ErrorHandlerService,
        private router: Router
    ) {
    }

    public ngOnInit(): void {
        this.refreshSubmissions();
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public refreshSubmissions(): void {
        this.loadingService.show();
        this.submissionService.getSubmissionSummaries()
            .pipe(
                take(1),
                finalize(() => this.loadingService.hide())
            )
            .subscribe({
                next: (pageSubmissions: SubmissionPage): void => {
                    this.submissionDataSource.data = pageSubmissions.content!;
                },
                error: (error: HttpErrorResponse): void => {
                    this.errorHandlerService.showError(error);
                }
            });
    }

    public createNewSubmission(): void {
        this.loadingService.show();
        this.submissionService.createSubmission()
            .pipe(
                take(1),
                finalize(() => this.loadingService.hide())
            )
            .subscribe({
                next: (submission: Submission): void => {
                    this.submissionService.selectedSubmission = submission;
                    this.router.navigate(['overview']);
                },
                error: (error: HttpErrorResponse): void => {
                    this.errorHandlerService.showError(error);
                }
            });
    }

    public delete(submission: Submission): void {

        const dialogRef: MatDialogRef<any> = this.dialog.open(ConfirmDialogComponent, {
            width: '33%',
            data: {
                content: `Are you sure you want to delete "${submission.overview.deviceRegistration.productName}"?`
            }
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.loadingService.show()
                this.submissionService.deleteSubmission(submission)
                    .pipe(
                        take(1),
                        finalize(() => this.loadingService.hide())
                    )
                    .subscribe({
                        next: (): void => {
                            // Reset the selected object if it is the object being deleted.
                            if (this.submissionService.selectedSubmission.id === submission.id) {
                                this.submissionService.selectedSubmission = {} as Submission;
                            }
                            this.refreshSubmissions();
                        },
                        error: (error: HttpErrorResponse): void => {
                            this.errorHandlerService.showError(error);
                        }
                    });
            }
        });
    }

    public edit(submission: Submission) {
        this.loadingService.show()
        this.submissionService.getSubmission(submission.id!)
            .pipe(
                take(1),
                finalize(() => this.loadingService.hide())
            )
            .subscribe({
                next: (submission: Submission): void => {
                    this.submissionService.selectedSubmission = submission;
                    this.router.navigate([`/${OverviewRoutes[0].path}`]);
                },
                error: (error: HttpErrorResponse): void => {
                    this.errorHandlerService.showError(error);
                }
            });
    }
}

@Component({
    selector: 'app-confirm-dialog',
    template: `
        <h2 mat-dialog-title>Confirmation</h2>
        <mat-dialog-content>
            <p>{{ data.content }}</p>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button (click)="onCancel()">Cancel</button>
            <button mat-button color="primary" (click)="onConfirm()">Confirm</button>
        </mat-dialog-actions>
    `,
    standalone: true,
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule
    ]
})
export class ConfirmDialogComponent {

    constructor(
        private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
    }

    onConfirm(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close(false);
    }
}
