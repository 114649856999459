<button color="primary" mat-button (click)="openGuidance()" *ngIf="guidanceContent || resources.length > 0"><mat-icon
    fontIcon="help"></mat-icon>Guidance</button>
<button color="primary" mat-button (click)="openTemplates()" *ngIf="templates.length > 0"><mat-icon
    fontIcon="description"></mat-icon>Templates</button>

<ng-template #guidanceTemplate>
    <mat-tab-group>
        <mat-tab label="Guidance" *ngIf="guidanceContent">
            <ng-container *ngTemplateOutlet="guidanceContent"></ng-container>
        </mat-tab>
        <mat-tab label="Resources" *ngIf="resources.length > 0">
            <ul>
                <li *ngFor="let resource of resources">
                    <a [href]="handle(resource.path)" target="_blank" mat-button color="primary">
                        {{ resource.name }}
                        <mat-icon>{{ resource.icon }}</mat-icon>
                    </a>
                </li>
            </ul>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #templatesTemplate>
    <mat-tab-group>
        <mat-tab label="Templates">
            <ul>
                <li *ngFor="let template of templates">
                    <a [href]="handle(template.path)" target="_blank" mat-button color="primary">{{ template.name }}
                        <mat-icon>{{ template.icon }}</mat-icon></a>
                </li>
            </ul>
        </mat-tab>
    </mat-tab-group>
</ng-template>
