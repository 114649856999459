import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Injectable } from '@angular/core';
import { LoadingComponent } from "src/app/components/widgets/loading/loading.component";

@Injectable({
    providedIn: 'root'
})
export class LoadingService {
    private overlayRef: OverlayRef | null = null;

    constructor(private overlay: Overlay) {
    }

    public get isLoading(): boolean {
        return !!this.overlayRef;
    }

    public show(): void {
        if (!this.overlayRef) {
            this.overlayRef = this.overlay.create({
                hasBackdrop: true,
                backdropClass: 'dark-backdrop',
                positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically()
            });
            const spinnerOverlayPortal = new ComponentPortal(LoadingComponent);
            this.overlayRef.attach(spinnerOverlayPortal);
        }
    }

    public hide(): void {
        if (!!this.overlayRef) {
            this.overlayRef.dispose();
            this.overlayRef = null;
        }
    }
}
