/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UploadedFile { 
    id?: number;
    fileName?: string;
    fileDiscriminator?: UploadedFile.FileDiscriminatorEnum;
    fileSize?: number;
    uploadDate?: string;
}
export namespace UploadedFile {
    export type FileDiscriminatorEnum = 'SUMMARY_OF_STUDIES' | 'PRECISION_TESTING' | 'LINEARITY_TESTING' | 'ANALYTICAL_SPECIFICITY_TESTING' | 'ASSAY_MEASUREMENT_RANGE_TESTING' | 'ANALYTICAL_SENSITIVITY_TESTING' | 'ASSAY_CUTOFF_TESTING' | 'TRACEABILITY_TESTING' | 'STABILITY_TESTING' | 'ANALYTICAL_PERFORMANCE_SUPPORTING_DATA' | 'METHOD_COMPARISON_STUDY' | 'MATRIX_COMPARISON_STUDY' | 'CLINICAL_SENSITIVITY_SPECIFICITY_STUDIES' | 'CLINICAL_CUTOFF_INFORMATION' | 'CLINICAL_SUPPORTING_DATA' | 'REFERENCE_RANGE_EXPECTED_VALUES' | 'PRO' | 'PPI' | 'STATEMENT_OF_COMPLIANCE_US' | 'STATEMENT_OF_COMPLIANCE_OUS' | 'ANIMAL_TESTING_REPORT' | 'ANIMAL_STUDY_PROTOCOL' | 'ANIMAL_STUDY_REPORT' | 'UNKNOWN';
    export const FileDiscriminatorEnum = {
        SummaryOfStudies: 'SUMMARY_OF_STUDIES' as FileDiscriminatorEnum,
        PrecisionTesting: 'PRECISION_TESTING' as FileDiscriminatorEnum,
        LinearityTesting: 'LINEARITY_TESTING' as FileDiscriminatorEnum,
        AnalyticalSpecificityTesting: 'ANALYTICAL_SPECIFICITY_TESTING' as FileDiscriminatorEnum,
        AssayMeasurementRangeTesting: 'ASSAY_MEASUREMENT_RANGE_TESTING' as FileDiscriminatorEnum,
        AnalyticalSensitivityTesting: 'ANALYTICAL_SENSITIVITY_TESTING' as FileDiscriminatorEnum,
        AssayCutoffTesting: 'ASSAY_CUTOFF_TESTING' as FileDiscriminatorEnum,
        TraceabilityTesting: 'TRACEABILITY_TESTING' as FileDiscriminatorEnum,
        StabilityTesting: 'STABILITY_TESTING' as FileDiscriminatorEnum,
        AnalyticalPerformanceSupportingData: 'ANALYTICAL_PERFORMANCE_SUPPORTING_DATA' as FileDiscriminatorEnum,
        MethodComparisonStudy: 'METHOD_COMPARISON_STUDY' as FileDiscriminatorEnum,
        MatrixComparisonStudy: 'MATRIX_COMPARISON_STUDY' as FileDiscriminatorEnum,
        ClinicalSensitivitySpecificityStudies: 'CLINICAL_SENSITIVITY_SPECIFICITY_STUDIES' as FileDiscriminatorEnum,
        ClinicalCutoffInformation: 'CLINICAL_CUTOFF_INFORMATION' as FileDiscriminatorEnum,
        ClinicalSupportingData: 'CLINICAL_SUPPORTING_DATA' as FileDiscriminatorEnum,
        ReferenceRangeExpectedValues: 'REFERENCE_RANGE_EXPECTED_VALUES' as FileDiscriminatorEnum,
        Pro: 'PRO' as FileDiscriminatorEnum,
        Ppi: 'PPI' as FileDiscriminatorEnum,
        StatementOfComplianceUs: 'STATEMENT_OF_COMPLIANCE_US' as FileDiscriminatorEnum,
        StatementOfComplianceOus: 'STATEMENT_OF_COMPLIANCE_OUS' as FileDiscriminatorEnum,
        AnimalTestingReport: 'ANIMAL_TESTING_REPORT' as FileDiscriminatorEnum,
        AnimalStudyProtocol: 'ANIMAL_STUDY_PROTOCOL' as FileDiscriminatorEnum,
        AnimalStudyReport: 'ANIMAL_STUDY_REPORT' as FileDiscriminatorEnum,
        Unknown: 'UNKNOWN' as FileDiscriminatorEnum
    };
}


