import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { catchError, finalize, Observable, ReplaySubject, take, throwError } from "rxjs";
import { ErrorHandlerService } from "src/app/services/error-handler.service";
import { LoadingService } from "src/app/services/loading-service.service";
import { SubmissionService } from "src/app/services/submission.service";
import { Submission } from "src/app/backend";


@Injectable({
    providedIn: 'root'
})
export class DeactivationService {

    constructor(
        private errorHandlerService: ErrorHandlerService,
        private loadingService: LoadingService,
        private submissionService: SubmissionService
    ) {
    }

    public deactivate(hasUnsavedChanges: boolean, patchSubmission: (submission: Submission) => Submission): Observable<boolean> {
        const result: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
        if (hasUnsavedChanges) {
            this.loadingService.show();
            const submission: Submission = patchSubmission(this.submissionService.selectedSubmission);
            this.submissionService.patchSelectedSubmission(submission)
                .pipe(
                    take(1),
                    catchError((error: HttpErrorResponse) => {
                        this.errorHandlerService.handleError(error);
                        result.next(false);
                        result.complete();
                        return throwError(() => error);
                    }),
                    finalize(() => {
                        this.loadingService.hide();
                    })
                )
                .subscribe((submission: Submission): void => {
                    this.submissionService.selectedSubmission = submission;
                    result.next(true);
                    result.complete();
                });
        } else {
            result.next(true);
            result.complete();
        }
        return result.asObservable();
    }
}
