<app-step id="comparison-studies" title="Comparison Studies">
    <ng-container subtitle>
        <p>The Comparison Studies section in a 510(k) submission presents data from studies that compare the new device to a legally marketed predicate device. These studies aim to demonstrate that the new device is at least as safe and effective, or "substantially equivalent," to the predicate. The comparison typically includes aspects such as device design, material, intended use, performance, and other features. This substantial equivalence is a key factor in the FDA's decision to grant clearance for the new device.</p>
    </ng-container>
    <ng-container content>

        <p>If the relevant study has not been performed, please provide a statement for why the information is not applicable.</p>

        <p>
            Attach Method Comparison Study.
            <app-guidance>
                <ng-template>
                    <p>An FDA IVD (In Vitro Diagnostic) method comparison study is a type of evaluation conducted to assess the agreement or concordance between different diagnostic methods used to detect a specific medical condition or analyte within a patient's sample, such as blood, urine, or tissue. The goal of this study is to determine whether the different methods provide similar results when testing the same sample, ensuring that they are clinically interchangeable and can be used interchangeably in patient care.</p>
                    <p>Key Components of an FDA IVD Method Comparison Study:</p>
                    <ol>
                        <li>Sample Selection: A representative set of patient samples is selected to cover a range of concentrations or conditions relevant to the medical condition being diagnosed. These samples should be well-characterized and anonymized to maintain patient confidentiality.</li>
                        <li>Testing Procedures: The selected samples are analyzed using the different diagnostic methods being compared. Each method should be performed by trained operators following the manufacturer's instructions or standard operating procedures.</li>
                        <li>Data Analysis: Statistical methods are applied to analyze the results obtained from each method. Common statistical analyses include the calculation of mean differences, correlation coefficients, bias, precision, and limits of agreement between the methods.</li>
                        <li>Interpreting Results: The study evaluates whether the differences observed between the methods fall within acceptable limits for clinical interchangeability. If the methods demonstrate strong concordance, they can be considered equivalent and interchangeable for patient care.</li>
                        <li>Regulatory Submission: If the method comparison study demonstrates the clinical interchangeability of the new diagnostic method with a gold standard or an established method, the data is included in the regulatory submission to support the case for FDA approval or clearance.</li>
                    </ol>
                    <p>The specific requirements and guidelines for conducting an FDA IVD method comparison study can evolve over time, so it's essential to refer to the latest FDA guidance documents and regulations during the study design and execution process.</p>
                </ng-template>
            </app-guidance>
        </p>
        <app-drag-drop
            [currentFiles]="methodComparisonFiles"
            (onFilesAdded)="uploadMethodComparisonFiles($event)"
            (onFilesRemoved)="deleteMethodComparisonFiles($event)"></app-drag-drop>

        <p>
            Attach Matrix Comparison Study.
            <app-guidance>
                <ng-template>
                    <p>The term "matrix" refers to the biological or clinical sample type in which a diagnostic test is performed. Different sample matrices can include blood, serum, plasma, urine, cerebrospinal fluid (CSF), saliva, and tissue, among others. Each matrix may present unique challenges and characteristics that can influence the performance of diagnostic tests.</p>
                    <p>A "matrix comparison study" generally refers to an evaluation that assesses the performance of a diagnostic test when applied to different sample matrices. The goal is to determine if the test's results are consistent and reliable across various sample types, and if any matrix-related effects need to be considered in the test's interpretation.</p>
                    <p>Key Components of a Matrix Comparison Study:</p>
                    <ol>
                        <li>Sample Selection: A diverse set of well-characterized patient samples from different matrices is selected to cover a representative range of clinical conditions and analyte concentrations.</li>
                        <li>Testing Procedures: The diagnostic test is performed on each sample matrix according to the manufacturer's instructions or standard operating procedures.</li>
                        <li>Data Analysis: Statistical analysis is conducted to assess the agreement and correlation of test results between the different sample matrices. Parameters like mean differences, correlation coefficients, and bias are calculated.</li>
                        <li>Interpreting Results: The study evaluates whether the diagnostic test provides consistent and comparable results across various sample matrices or if adjustments or corrections are needed to account for matrix-related effects.</li>
                        <li>Clinical Implications: The study's findings help establish the suitability of the diagnostic test for different clinical scenarios and patient populations, considering the variety of sample matrices encountered in routine clinical practice.</li>
                    </ol>
                    <p>For the most up-to-date and specific information on FDA requirements for IVD studies, including matrix comparison studies, it's essential to refer to the latest FDA guidance documents and regulations applicable to the particular diagnostic test or medical device in question.</p>
                </ng-template>
            </app-guidance>
        </p>
        <app-drag-drop
            [currentFiles]="matrixComparisonFiles"
            (onFilesAdded)="uploadMatrixComparisonFiles($event)"
            (onFilesRemoved)="deleteMatrixComparisonFiles($event)"></app-drag-drop>
    </ng-container>
</app-step>
