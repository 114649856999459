<app-step title="Medical Device">
    <ng-container subtitle>
        <p>In this section, you will be asked to determine whether your device meets the definition of a medical device as defined by the FDA.</p>
        <p>Please refer to the FDA guidance document entitled, “How to Determine if Your Product is a Medical Device” found in the Resources tab below for additional information.</p>
    </ng-container>
    <ng-container content>
        <form [formGroup]="formGroup">
            <app-guidance [resources]="resourceBundle.medicalDevice"></app-guidance>
            <div class="ml-25">
                <p><mat-checkbox color="primary"
                                 formControlName="meetsCriteria1">is your medical device recognized in the official National Formulary, or the United States Pharmacopoeia, or a supplement to them?</mat-checkbox></p>
                <p><mat-checkbox color="primary"
                                 formControlName="meetsCriteria2">Is your medical device intended for the use in the diagnosis of disease or other conditions, or in the cure, mitigation, treatment, or prevention of disease, in man or other animals?</mat-checkbox></p>
                <p><mat-checkbox color="primary"
                                 formControlName="meetsCriteria3">Is your medical device intended to affect the structure or any function of the body of man or other animals, and which does not achieve its primary intended purposes through chemical action within or on the body of man or other animals?</mat-checkbox></p>
                <p><mat-checkbox color="primary" formControlName="noCriteriaMet">Is it none of the above?</mat-checkbox></p>
                <mat-chip-option color="warn" selected="true" selectable="false"
                                 *ngIf="medicalDeviceState === MedicalDeviceState.NON_MEDICAL_DEVICE">
                    <mat-icon matChipAvatar>favorite</mat-icon>
                    There is no need to continue as the device is not considered a medical device and does not need to be filed.
                </mat-chip-option>
            </div>
        </form>
    </ng-container>
</app-step>
