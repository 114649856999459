import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { RouterModule } from "@angular/router";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { DeactivationService } from "src/app/services/deactivation.service";
import { Observable, Subject, takeUntil } from "rxjs";
import { DragDropComponent } from "src/app/components/widgets/drag-drop/drag-drop.component";
import { GuidanceComponent, Resource } from "src/app/components/widgets/guidance/guidance.component";
import { StepComponent } from "src/app/components/widgets/step/step.component";

import { SubmissionService } from "src/app/services/submission.service";
import { Submission, UploadedFile } from "src/app/backend";

@Component({
    selector: 'app-analytical-performance',
    templateUrl: './analytical-performance.component.html',
    styleUrls: ['./analytical-performance.component.scss'],
    standalone: true,
    imports: [
        DragDropComponent,
        CommonModule,
        GuidanceComponent,
        MatButtonModule,
        MatSlideToggleModule,
        ReactiveFormsModule,
        RouterModule,
        StepComponent
    ]
})
export class AnalyticalPerformanceComponent implements OnInit, OnDestroy, Deactivatable {

    protected resourceBundle1: Resource[] = [
        {
            icon: 'info',
            name: '18011 - Recommended Content and Format of Non-Clinical Bench Performance Testing Information in Premarket Submissions.pdf',
            path: 'performance_testing/18011 - Recommended Content and Format of Non-Clinical Bench Performance Testing Information in Premarket Submissions.pdf'
        },
        {
            icon: 'info',
            name: '337 Guidance-for-the-Content-of-Premarket-Submissions-for-Software-Contained-in-Medical-Devices---Guidance-for-Industry-and-FDA-Staff.pdf',
            path: 'performance_testing/337 Guidance-for-the-Content-of-Premarket-Submissions-for-Software-Contained-in-Medical-Devices---Guidance-for-Industry-and-FDA-Staff.pdf'
        },
        {
            icon: 'info',
            name: 'Applying-Human-Factors-and-Usability-Engineering-to-Medical-Devices---Guidance-for-Industry-and-Food-and-Drug-Administration-Staff.pdf',
            path: 'performance_testing/Applying-Human-Factors-and-Usability-Engineering-to-Medical-Devices---Guidance-for-Industry-and-Food-and-Drug-Administration-Staff.pdf'
        },
        {
            icon: 'info',
            name: 'Statistical Guidance on Reporting Results from Studies Evaluating Diagnostic Tests - Guidance for Industry and FDA Staff _ FDA.pdf',
            path: 'performance_testing/Statistical Guidance on Reporting Results from Studies Evaluating Diagnostic Tests - Guidance for Industry and FDA Staff _ FDA.pdf'
        },
    ];
    protected templateBundle1 = [
        {
            icon: 'description',
            name: 'Analytical Protocol Template.docx',
            path: 'performance_testing/Analytical Protocol Template.docx'
        },
        {
            icon: 'description',
            name: 'Analytical Report Template.docx',
            path: 'performance_testing/Analytical Report Template.docx'
        },
        {
            icon: 'description',
            name: 'FRM-065 Analytical Protocol Template-01.docx',
            path: 'performance_testing/FRM-065 Analytical Protocol Template-01.docx'
        },
        {
            icon: 'description',
            name: 'FRM-066 Analytical Report Template-01.docx',
            path: 'performance_testing/FRM-066 Analytical Report Template-01.docx'
        },
        {
            icon: 'description',
            name: 'Flex Test Protocol Template.docx',
            path: 'performance_testing/Flex Test Protocol Template.docx'
        },
        {
            icon: 'description',
            name: 'Flex Test Report Template.docx',
            path: 'performance_testing/Flex Test Report Template.docx'
        },
        {
            icon: 'info',
            name: 'Useability Testing Guidance.docx',
            path: 'performance_testing/Useability Testing Guidance.docx'
        }
    ];

    resourceBundle2: Resource[] = [
        {
            icon: 'info',
            name: 'FDA Guidance  1757 Applying Human Factors',
            path: 'performance_testing/FDA Guidance  1757 Applying Human Factors.pdf'
        },
        {
            icon: 'info',
            name: 'FDA-Perspectives-on--Human-Factors-in-Device-Development-(June-2012).pdf',
            path: 'performance_testing/FDA-Perspectives-on--Human-Factors-in-Device-Development-(June-2012).pdf'
        }
    ];

    templatesBundle2 = [
        {
            icon: 'info',
            name: 'Usability Testing Guidance.docx',
            path: 'performance_testing/Usability Testing Guidance.docx'
        }
    ];

    public formGroup = new FormGroup({
        hasPrecisionTesting: new FormControl(false),
        hasLinearityTesting: new FormControl(false),
        hasSensitivityTesting: new FormControl(false),
        hasMeasurementRangeTesting: new FormControl(false),
        hasSpecificityTesting: new FormControl(false),
        hasCutoffTesting: new FormControl(false),
        hasTraceabilityTesting: new FormControl(false),
        hasStabilityTesting: new FormControl(false),
        hasOtherTesting: new FormControl(false)
    });

    private destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService
    ) {
    }

    public ngOnInit(): void {
        this.formGroup.patchValue(this.submissionService.selectedSubmission.performanceTesting!);
        this.formGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            // this.submissionService.selectedSubmission.performanceTesting = this.analyticalTesting;
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(this.formGroup.dirty, (submission: Submission) => this.patchSubmission(submission));
    }

    private patchSubmission(submission: Submission): Submission {
        return {
            ...submission,
            // performanceTesting: this.formGroup.value
        };
    }

    get hasPrecisionTesting(): boolean {
        return !!this.formGroup.controls.hasPrecisionTesting.value;
    }

    protected get precisionTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.precisionTestingFiles || new Set();
    }

    protected uploadPrecisionTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "PRECISION_TESTING", files);
    }

    protected deletePrecisionTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "PRECISION_TESTING", files);
    }

    protected get linearityTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.linearityTestingFiles || new Set();
    }

    protected uploadLinearityTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "LINEARITY_TESTING", files);
    }

    protected deleteLinearityTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "LINEARITY_TESTING", files);
    }

    protected get sensitivityTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.analyticalSensitivityTestingFiles || new Set();
    }

    protected uploadSensitivityTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "ANALYTICAL_SENSITIVITY_TESTING", files);
    }

    protected deleteSensitivityTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "ANALYTICAL_SENSITIVITY_TESTING", files);
    }

    protected get measurementRangeTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.assayMeasurementRangeTestingFiles || new Set();
    }

    protected uploadMeasurementRangeTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "ASSAY_MEASUREMENT_RANGE_TESTING", files);
    }

    protected deleteMeasurementRangeTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "ASSAY_MEASUREMENT_RANGE_TESTING", files);
    }

    protected get specificityTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.analyticalSpecificityTestingFiles || new Set();
    }

    protected uploadSpecificityTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "ANALYTICAL_SPECIFICITY_TESTING", files);
    }

    protected deleteSpecificityTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "ANALYTICAL_SPECIFICITY_TESTING", files);
    }

    protected get cutoffTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.assayCutoffTestingFiles || new Set();
    }

    protected uploadCutoffTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "ASSAY_CUTOFF_TESTING", files);
    }

    protected deleteCutoffTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "ASSAY_CUTOFF_TESTING", files);
    }

    protected get traceabilityTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.traceabilityTestingFiles || new Set();
    }

    protected uploadTraceabilityTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "TRACEABILITY_TESTING", files);
    }

    protected deleteTraceabilityTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "TRACEABILITY_TESTING", files);
    }

    protected get stabilityTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.stabilityTestingFiles || new Set();
    }

    protected uploadStabilityTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "STABILITY_TESTING", files);
    }

    protected deleteStabilityTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "STABILITY_TESTING", files);
    }

    protected get otherTestingFiles(): Set<UploadedFile> {
        return this.submissionService.selectedSubmission.performanceTesting?.analyticalPerformanceSupportingDataFiles || new Set();
    }

    protected uploadOtherTestingFiles(files: Set<File>) {
        this.submissionService.uploadFiles(this.submissionService.selectedSubmission, "ANALYTICAL_PERFORMANCE_SUPPORTING_DATA", files);
    }

    protected deleteOtherTestingFiles(files: Set<File>) {
        this.submissionService.removeFiles(this.submissionService.selectedSubmission, "ANALYTICAL_PERFORMANCE_SUPPORTING_DATA", files);
    }

    get hasLinearityTesting(): boolean {
        return !!this.formGroup.controls.hasLinearityTesting.value;
    }

    get hasSensitivityTesting(): boolean {
        return !!this.formGroup.controls.hasSensitivityTesting.value;
    }

    get hasMeasurementRangeTesting(): boolean {
        return !!this.formGroup.controls.hasMeasurementRangeTesting.value;
    }

    get hasSpecificityTesting(): boolean {
        return !!this.formGroup.controls.hasSpecificityTesting.value;
    }

    get hasCutoffTesting(): boolean {
        return !!this.formGroup.controls.hasCutoffTesting.value;
    }

    get hasTraceabilityTesting(): boolean {
        return !!this.formGroup.controls.hasTraceabilityTesting.value;
    }

    get hasStabilityTesting(): boolean {
        return !!this.formGroup.controls.hasStabilityTesting.value;
    }

    get hasOtherTesting(): boolean {
        return !!this.formGroup.controls.hasOtherTesting.value;
    }
}
