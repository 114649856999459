import { Routes } from "@angular/router";
import { SidenavLayoutComponent } from "src/app/components/layouts/sidenav-layout/sidenav-layout.component";


import { AuthGuard } from "src/app/guards/auth.guard";
import { DashboardComponent } from "src/app/components/pages/dashboard/dashboard.component";
import { LoginComponent } from "src/app/components/pages/login/login.component";
import { ProfileComponent } from "src/app/components/pages/profile/profile.component";
import { OverviewRoutes } from "src/app/components/pages/overview/overview.routes";
import {
    PerformanceTestingRoutes
} from "src/app/components/pages/performance-testing/performance-testing.routes";



export enum RoutePath {
    LOGIN = 'login',
    PROFILE = 'profile',
    DASHBOARD = 'dashboard',
}

export const appRoutes: Routes = [
    { path: '', redirectTo: RoutePath.LOGIN, pathMatch: 'full' },
    { path: RoutePath.LOGIN, component: LoginComponent },
    {
        path: '',
        component: SidenavLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', redirectTo: RoutePath.DASHBOARD, pathMatch: 'full' },
            { path: RoutePath.DASHBOARD, component: DashboardComponent },
            { path: RoutePath.PROFILE, component: ProfileComponent },
            ...OverviewRoutes,
            ...PerformanceTestingRoutes
        ]
    },
    // { path: '**', redirectTo: 'login' }
];
