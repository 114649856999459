<div class="w-100p">
    <div class="header mb-25 w-100p">
        <div *ngIf="deviceRegistration.productName && deviceRegistration.description">
            <h3><strong class="mr-10">{{ deviceRegistration.productName }}</strong></h3>
            <em>
                <div>Recommendation: {{ recommendation }}</div>
                <div class="mr-10">Review Panel: {{ deviceRegistration.classificationPanel ? deviceRegistration.classificationPanel : 'No classification panel has been selected.' }}</div>
            </em>
            <p>{{ deviceRegistration.description }}</p>
        </div>
    </div>

    <div class="mb-50" *ngIf="predicateDevices">
        <table mat-table matSort [dataSource]="predicateDevices"
               *ngIf="predicateDevices.length > 0; else noPredicateDevice">

            <ng-container matColumnDef="deviceName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Predicate Device Name</th>
                <td mat-cell *matCellDef="let device">{{ device["deviceName"] }}</td>
            </ng-container>

            <ng-container matColumnDef="kNumber">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>K Number</th>
                <td mat-cell *matCellDef="let device">
                    <a href="javascript:void(0);"
                       (click)="openDeviceSummary(device['kNumber'])">{{ device["kNumber"] }}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="decisionDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Decision Date</th>
                <td mat-cell *matCellDef="let device">{{ device["decisionDate"] }}</td>
            </ng-container>

            <ng-container matColumnDef="productCode">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Product Code</th>
                <td mat-cell *matCellDef="let device">
                    <a href="javascript:void(0);"
                       (click)="openProductClassification(device['productCode'])">{{ device["productCode"] }}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="classification">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Classification</th>
                <td mat-cell *matCellDef="let device">{{ device["classification"] }}</td>
            </ng-container>

            <ng-container matColumnDef="reviewAdviseComm">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Review Panel</th>
                <td mat-cell *matCellDef="let device">{{ device["reviewAdviseComm"] }}</td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="this.displayedColumns.length">No devices selected</td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <ng-template #noPredicateDevice>
            <span matCardSubtitle>No predicate devices have been selected.</span>
        </ng-template>
    </div>

    <div *ngIf="!predicateDevices">
        No predicate devices have been selected.
    </div>

    <div class="mb-50" *ngIf="submission?.overview?.deviceRegistration?.technology">
        <h3 class=""><strong class="mr-10">{{ documents.viewValue }} Experiments</strong></h3>
        <table mat-table matSort [dataSource]="documents.protocols"
               *ngIf="documents.protocols.length > 0; else noTechnology">

            <ng-container matColumnDef="category">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Protocols</th>
                <td mat-cell *matCellDef="let protocol">{{ protocol["category"] }}</td>
            </ng-container>

            <ng-container matColumnDef="clsi">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>CLSI Number</th>
                <td mat-cell *matCellDef="let protocol">{{ protocol["clsi"] }}</td>
            </ng-container>

            <ng-container matColumnDef="title">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Title</th>
                <td mat-cell *matCellDef="let protocol">{{ protocol["title"] }}</td>
            </ng-container>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" [colSpan]="this.protocolDisplayedColumns.length">No devices selected</td>
            </tr>

            <tr mat-header-row *matHeaderRowDef="protocolDisplayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: protocolDisplayedColumns;"></tr>
        </table>
        <ng-template #noTechnology>
            <span matCardSubtitle>No technology selected.</span>
        </ng-template>
    </div>

    <div class="bm-50">
        @if (workflow === Workflow.PMN) {
            <h4 class="subsection-title">Anticipated Clinical Trials</h4>
            <p>
                A Clinical Comparison Study Protocol is a structured plan designed to assess the safety and effectiveness of a medical device or intervention by comparing it to a control or existing treatment within a clinical setting. This protocol outlines the objectives, study design, methodology, and statistical analysis plan necessary for conducting a rigorous evaluation of the device's performance against predefined benchmarks.
                <app-guidance [templates]="templateBundle1"></app-guidance>
            </p>
        }
        @if (workflow === Workflow.DE_NOVO) {
            <h4 class="subsection-title">Anticipated Clinical Trials</h4>
            <p>
                The De Novo Safety and Effectiveness Protocol is a regulatory pathway offered by the FDA that enables a streamlined review process for novel, low- to moderate-risk medical devices that do not have a clear predicate device for comparison. Unlike the 510(k) submission, which requires substantial equivalence to a legally marketed device, the De Novo process allows manufacturers to establish that their device meets appropriate safety and effectiveness criteria without relying on an existing predicate.
                <app-guidance [templates]="templateBundle2"></app-guidance>
            </p>
        }
    </div>

    <div class="bm-50">
        <h4 class="subsection-title">Q-Submission</h4>
        <p>
            It is strongly advised that you consult with our regulatory experts and initiate a Q-Submission to the FDA to ensure that your device is classified correctly and that you have the necessary data to support your submission.
            <app-guidance [templates]="templateBundle3">
                <ng-template>
                    <p>A Q-Submission refers to a pre-submission pathway that the FDA provides for medical device companies. It allows for an exchange of information and feedback between the FDA and the company before the formal submission of a 510(k) application. The Q-Sub process is part of the FDA’s broader Pre-Submission Program and serves as a way for device developers to seek advice, clarify requirements, or discuss specific questions regarding their upcoming submissions.</p>
                    <p>The main goals of a Q-Sub are to:</p>
                    <ul>
                        <li>Request Feedback: It enables companies to ask for FDA feedback on specific regulatory, scientific, or procedural issues related to their device or testing protocols.</li>
                        <li>Clarify Requirements: Companies can seek clarity on the regulatory path, test data requirements, or other components of their submission to improve the likelihood of a successful 510(k).</li>
                        <li>Save Time: By addressing questions early, a Q-Sub can help avoid delays or additional rounds of review in the formal 510(k) submission.</li>
                    </ul>
                    <p>Through the Q-Sub process, companies can submit questions, engage in meetings, and receive preliminary responses from the FDA, which can be invaluable for a well-prepared 510(k) submission.</p>
                </ng-template>
            </app-guidance>
        </p>
    </div>
</div>
