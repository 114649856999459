<div *ngIf="this.productClassification">
    <h1 mat-dialog-title>Product Classification {{ this.productClassification.productCode }} - {{ this.productClassification.deviceName }}</h1>
    <div mat-dialog-content class="grid-container">

        <div>Definition</div>
        <div>{{ this.productClassification.definition }}</div>

        <div>Physical State</div>
        <div>{{ this.productClassification.physicalState }}</div>

        <div>Technical Method</div>
        <div>{{ this.productClassification.technicalMethod }}</div>

        <div>Target Area</div>
        <div>{{ this.productClassification.targetArea }}</div>

        <div>Regulation Medical Specialty</div>
        <div>{{ this.productClassification.medicalSpecialty }}</div>

        <div>Review Panel</div>
        <div>{{ this.productClassification.reviewPanel }}</div>

        <div>Submission Type</div>
        <div>{{ this.getSubmissionString(this.productClassification.submissionType ?? '') }}</div>

        <div>Regulation Number</div>
        <div>{{ this.productClassification.regulationNumber }}</div>

        <div>Device Class</div>
        <div>{{ this.productClassification.deviceClass }}</div>

        <div>GMP Exempt</div>
        <div>{{ this.productClassification.gmpExemptFlag }}</div>

        <div>Summary Malfunction Reporting</div>
        <div>{{ this.productClassification.summaryMalfunctionReporting }}</div>

        <div>Implanted Device</div>
        <div>{{ this.productClassification.implantFlag }}</div>

        <div>Life-Sustain/Support Device</div>
        <div>{{ this.productClassification.lifeSustainSupportFlag }}</div>

        <div>Third Party Review</div>
        <div>{{ this.productClassification.thirdPartyReviewEligible }}</div>
    </div>
</div>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" mat-dialog-close>Close</button>
</mat-dialog-actions>
