<mat-sidenav-container class="sidenav-container" autosize>
        <mat-sidenav class="sidenav" mode="side" opened>
            <div class="title-container">
                <img ngSrc="/images/LLS-Logo.jpg" height="50" width="65" alt="">
                <span class="title">COMPASS</span>
            </div>

            <mat-nav-list>
                <mat-nav-list>
                    <a mat-list-item routerLinkActive="routerLinkActive" routerLink="/{{ routePaths.DASHBOARD }}">Dashboard</a>
                </mat-nav-list>

                <mat-divider></mat-divider>
                <div class="selectedSubmission"><h4>{{ selectedSubmission?.overview?.deviceRegistration?.productName || '' }}</h4></div>

                @for (navItem of navItems | keyvalue; track navItem) {
                    <mat-expansion-panel class="mat-elevation-z0" expanded [disabled]="!hasSelectedSubmission()">
                        <mat-expansion-panel-header>{{ navItem.key }}</mat-expansion-panel-header>
                        <mat-nav-list>
                            @for (routes of navItem.value; track routes.path) {
                                @for (route of routes.children; track route.path) {
                                    <a *ngIf="route.path" mat-list-item [disabled]="!hasSelectedSubmission()" routerLinkActive="routerLinkActive" routerLink="/{{ routes.path }}/{{ route.path }}">{{ route.data!['title'] }}</a>
                                }
                            }
                        </mat-nav-list>
                    </mat-expansion-panel>
                }
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content class="content-container">
            <mat-toolbar class="toolbar">
                <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
                    <mat-icon fontIcon="expand_more"></mat-icon>
                    {{ username }}
                </button>
                <mat-menu class="actions-menu" #menu="matMenu">
                    <button mat-menu-item routerLink="profile"><mat-icon fontIcon="manage_accounts"></mat-icon> Profile</button>
                    <button mat-menu-item (click)="logout()"><mat-icon fontIcon="logout"></mat-icon> Logout</button>
                </mat-menu>
            </mat-toolbar>

            <router-outlet></router-outlet>

            <button *ngIf="nextSectionUrl" mat-raised-button color="primary" (click)="nextSection()">Next Section</button>
        </mat-sidenav-content>
    </mat-sidenav-container>
