<div class="background-container">
    <div class="page-container">
        <div class="page-header title">
            <a href="https://www.lakeside.life/">Lakeside Life Science</a>
        </div>
        <div class="page-body">
            <mat-card class="login-card">
                <mat-card-header class="login-card-header">
                    <mat-card-title>Compass</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <amplify-authenticator [services]=services [loginMechanisms]="['email']" [formFields]="formFields" [hideSignUp]="false">
                        <ng-template amplifySlot="authenticated" let-user="user" let-signOut="signOut"><button (click)="signOut()">Sign Out</button></ng-template>
                        <ng-template amplifySlot="footer">&nbsp;</ng-template>
                    </amplify-authenticator>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>
