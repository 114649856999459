import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { RouterModule } from "@angular/router";
import { Submission } from "src/app/backend";
import { Deactivatable } from "src/app/guards/can-deactivate.guard";
import { DeactivationService } from "src/app/services/deactivation.service";
import { Observable, Subject } from "rxjs";
import { StepComponent } from "src/app/components/widgets/step/step.component";
import { SubmissionService } from "src/app/services/submission.service";

@Component({
    selector: 'app-guidance-and-special-controls-adherence',
    templateUrl: './guidance-and-special-controls-adherence.component.html',
    styleUrls: ['./guidance-and-special-controls-adherence.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        RouterModule,
        StepComponent
    ]
})
export class GuidanceAndSpecialControlsAdherenceComponent implements OnInit, OnDestroy, Deactivatable {

    public formGroup = new FormGroup({
        controlsSummary: new FormControl<string>(''),
        alternativeSummary: new FormControl<string>(''),
    });

    private destroy$: Subject<void> = new Subject<void>();

    public constructor(
        private deactivationService: DeactivationService,
        private submissionService: SubmissionService
    ) {
        this.formGroup.patchValue(this.submissionService.selectedSubmission.performanceTesting?.guidanceAndSpecialControlsAdherence!);
    }

    public ngOnInit(): void {
        this.formGroup.patchValue(this.submissionService.selectedSubmission.performanceTesting?.guidanceAndSpecialControlsAdherence!);
        this.formGroup.valueChanges.subscribe(() => {
            this.submissionService.selectedSubmission.performanceTesting!.guidanceAndSpecialControlsAdherence = {
                ...this.submissionService.selectedSubmission.performanceTesting!.guidanceAndSpecialControlsAdherence!,
                controlsSummary: this.formGroup.controls.controlsSummary.value!,
                alternativeSummary: this.formGroup.controls.alternativeSummary.value!,
            };
        });
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public deactivate(): Observable<boolean> {
        return this.deactivationService.deactivate(false, (submission: Submission) => submission);
    }
}
