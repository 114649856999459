<app-step title="Device Registration">
    <ng-container subtitle>
        <p>In this section, you will be providing information to describe the general purpose of your device and its function.</p>
    </ng-container>
    <ng-container content>
        <form class="form-wrapper" [formGroup]="formGroup">
            <mat-form-field appearance="fill">
                <mat-label>Product Name</mat-label>
                <input matInput formControlName="productName">
                <mat-hint>Enter Analyte or if using Device Name ensure that this includes and begins with the analyte under scrutiny.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Description</mat-label>
                <input matInput formControlName="description">
            </mat-form-field>

            <app-guidance [resources]="resourceBundle.deviceType">
                <ng-template>
                    <p>Please consider your entire test system and select the content that needs premarket clearance in this submission.</p>
                    <p>If this is a software only submission, please select "Instrument" as your device type.</p>
                    <p>If part of your test system has been previously cleared or granted by FDA, please reference FDA's guidance entitled "Deciding When to Submit a 510(k) for a Change to an Existing Device" to determine whether the previously cleared or granted device should be included in this submission.</p>
                </ng-template>
            </app-guidance>
            <div class="toggle-container mb-25">
                <mat-slide-toggle formControlName="isInstrument"
                                  color="primary">Device is an instrument</mat-slide-toggle>
                <mat-slide-toggle formControlName="isAssay" color="primary">Device is an assay</mat-slide-toggle>
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Technology</mat-label>
                <mat-select formControlName="technology">
                    @for (technology of technologies | keyvalue; track technology) {
                        <mat-option [value]="technology.key">{{technology.value.viewValue}}</mat-option>
                    }
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Analysis Type</mat-label>
                <mat-select formControlName="analysisType">
                    <mat-option value="Qualitative">Qualitative</mat-option>
                    <mat-option value="Quantitative">Quantitative</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Specimen Type</mat-label>
                <input type="text" matInput formControlName="specimenType" [matAutocomplete]="autoSpecimenType">
                <mat-autocomplete autoActiveFirstOption #autoSpecimenType="matAutocomplete">
                    <mat-option *ngFor="let option of filteredSpecimenTypeOptions$ | async"
                                [value]="option">{{ option }}</mat-option>
                </mat-autocomplete>
                <mat-hint>Which type of sample matrices are used? You may select a predefined option or write your own.</mat-hint>
            </mat-form-field>

            <app-guidance>
                <ng-template>
                    <p>The FDA defines a <strong>disease</strong> as a "pathological condition of the body" that affects the normal structure or function of an individual. In the FDA's regulatory framework, diseases are typically characterized by specific symptoms, signs, or abnormal physiological processes. When a drug is evaluated for safety and efficacy, it is usually tested and approved for specific diseases or indications.</p>
                    <p>The FDA uses the term <strong>condition</strong> in a broader sense to describe a health-related state, which can include diseases, disorders, or other medical situations. It may refer to a specific set of symptoms or abnormalities that are not necessarily classified as a disease or do not have a specific diagnostic label. The term "condition" is often used to describe broader therapeutic areas or categories of health issues.</p>
                    <p>It's important to note that the FDA's usage of these terms may vary depending on the specific context and purpose within its regulatory framework. The definitions and interpretations of these terms may also vary in other countries or regulatory bodies.</p>
                </ng-template>
            </app-guidance>
            <mat-form-field appearance="fill">
                <mat-label>Clinical Use</mat-label>
                <mat-select formControlName="clinicalUse">
                    <mat-option value="Disease">Disease</mat-option>
                    <mat-option value="Condition">Condition</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Clinical Purpose</mat-label>
                <input type="text" matInput formControlName="clinicalPurpose" [matAutocomplete]="autoClinicalPurpose">
                <mat-autocomplete autoActiveFirstOption #autoClinicalPurpose="matAutocomplete">
                    <mat-option *ngFor="let option of filteredClinicalPurposeOptions$ | async"
                                [value]="option">{{ option }}</mat-option>
                </mat-autocomplete>
                <mat-hint>You may select a predefined option or write your own.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Intended Gender</mat-label>
                <mat-select formControlName="intendedGender">
                    <mat-option value="Female">Female</mat-option>
                    <mat-option value="Male">Male</mat-option>
                    <mat-option value="All">All</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Intended Age Group</mat-label>
                <mat-select formControlName="intendedPopulation" multiple>
                    <mat-option value="Geriatric (65+)">Geriatric (65+)</mat-option>
                    <mat-option value="Adult (50+)">Adult (50+)</mat-option>
                    <mat-option value="Adult (18+)">Adult (18+)</mat-option>
                    <mat-option value="Adolescents (12-18 yrs.)">Adolescents (12-18 yrs.)</mat-option>
                    <mat-option value="Children (2-12 yrs.)">Children (2-12 yrs.)</mat-option>
                    <mat-option value="Infants (29 days to < 2 yrs.)">Infants (29 days to < 2 yrs.)</mat-option>
                    <mat-option value="Newborn (birth - 28 days)">Newborn (birth - 28 days)</mat-option>
                </mat-select>
                <mat-hint>Select all that apply.</mat-hint>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Test Setting</mat-label>
                <mat-select formControlName="setting" multiple>
                    <mat-option value="Central Laboratory">Central Laboratory</mat-option>
                    <mat-option
                        value="Home Environment">Home Environment</mat-option>
                    <mat-option
                        value="Professional Healthcare Facility">Professional Healthcare Facility (Physician Office Laboratory / Point of Care)</mat-option>
                </mat-select>
                <mat-hint>Select all that apply.</mat-hint>
            </mat-form-field>

            <app-guidance>
                <ng-template>
                    <p><strong>Chemistry</strong>: Assesses the safety and effectiveness of devices for clinical chemistry, clinical toxicology, endocrinology, and other related diagnostic tests.</p>
                    <p><strong>Hematology</strong>: This panel reviews and evaluates data concerning the safety and effectiveness of marketed and investigational in vitro devices for use in clinical laboratory medicine.</p>
                    <p><strong>Pathology</strong>: This panel reviews and evaluates data concerning the safety and effectiveness of marketed and investigational in vitro devices for use in pathology.</p>
                    <p><strong>Immunology:</strong>: Advises on devices used in immunology, including serology.</p>
                    <p><strong>Microbiology:</strong>: Reviews data on devices used in clinical microbiology, such as tests for infectious diseases.</p>
                    <p><strong>Unknown</strong>: Use if unsure</p>
                </ng-template>
            </app-guidance>
            <mat-form-field appearance="fill">
                <mat-label>Product Classification / Review Panel</mat-label>
                <mat-select formControlName="classificationPanel">
                    <mat-option *ngFor="let opt of classificationPanels"
                                [value]="opt.abbr">{{ opt.description }}</mat-option>
                </mat-select>
                <mat-hint>Your choice of Product Classification for your medical device can be confirmed by your Predicate Device selection and reviewing its respective Product Classification code.</mat-hint>
            </mat-form-field>
        </form>
    </ng-container>
</app-step>
